<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="entityFacade.isFiltering$ | async"></mat-progress-bar>
<div class="container">
	<h1>{{ pageTitle$ | async }}</h1>
	<ng-container *ngIf="entityFacade.filterOperatorForm$ | async as filterOperatorForm">
		<ng-container *ngIf="entityFacade.filterForm$ | async as filterForm">
			<mat-card class="table-wrapper">
				<div (scroll)="onScroll($event)">
					<table mat-table matSort (matSortChange)="onSortChange($event)" [dataSource]="entityFacade.listFiltered$ | async" class="{{ filterExpanded ? 'filter-toggled' : '' }}">
						>
						<ng-container matColumnDef="select-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>
									<mat-checkbox (change)="masterSelection$.next($event.checked)" [checked]="selection.hasValue() && isAllSelected$ | async" [indeterminate]="selection.hasValue() && (isAllSelected$ | async) === false">
									</mat-checkbox>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="select-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="select-content">
							<td mat-cell *matCellDef="let entity">
								<mat-checkbox (change)="$event ? selection.toggle(entity) : null" [checked]="selection.isSelected(entity)"> </mat-checkbox>
							</td>
						</ng-container>

						<ng-container matColumnDef="name-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Produkt</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="name-filter">
							<th mat-header-cell *matHeaderCellDef>
								<babylon-product-autocompletesingle [value]="filterForm.controls[0].controls.product.value" [controlId]="filterForm.controls[0].controls.product.id"></babylon-product-autocompletesingle>
							</th>
						</ng-container>
						<ng-container matColumnDef="name-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="productFacade.fetchOne(entity.product) | async as product">
									{{ product?.name }}<ng-container *ngIf="entity.publicationDate"> vom {{ entity.publicationDate | amDateFormat: 'DD.MM.YYYY (dd)' }}</ng-container>
								</ng-container>
								<babylon-fetch [facade]="productFacade" [value]="entity.product"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="order-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="order">Bestellung</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="order-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="order-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="orderFacade.fetchOne(entity.order) | async as order">
									<a [routerLink]="['/entity', 'order', 'change', order._id]">{{ order.number }}</a>
								</ng-container>
							</td>
						</ng-container>

						<ng-container matColumnDef="storageRoom-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="storagePlace">Lagerplatz</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="storageRoom-filter">
							<th mat-header-cell *matHeaderCellDef>
								<babylon-storage-place-autocompletesingle [value]="filterForm.controls[0].controls.storagePlace.value" [controlId]="filterForm.controls[0].controls.storagePlace.id"></babylon-storage-place-autocompletesingle>
							</th>
						</ng-container>
						<ng-container matColumnDef="storageRoom-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="storagePlaceFacade.fetchOne(entity.storagePlace) | async as storagePlace">{{ storagePlace?.room }} ({{ storagePlace?.identifier }}) </ng-container>
								<babylon-fetch [facade]="storagePlaceFacade" [value]="entity.storagePlace"> </babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="assignee-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="assignee">Bearbeiter</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="assignee-filter">
							<th mat-header-cell *matHeaderCellDef>
								<babylon-user-autocompletemultiple
									[placeholder]="'Bearbeiter'"
									[values]="filterForm.controls[0].controls.processInstance.controls.currentUser.value"
									[controlId]="filterForm.controls[0].controls.processInstance.controls.currentUser.id"></babylon-user-autocompletemultiple>
							</th>
						</ng-container>
						<ng-container matColumnDef="assignee-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-profile-picture [userId]="entity.processInstance.currentUser"> </babylon-profile-picture>
							</td>
						</ng-container>

						<ng-container matColumnDef="status-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="status">Status</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="status-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field"></div>
							</th>
						</ng-container>
						<ng-container matColumnDef="status-content">
							<td mat-cell *matCellDef="let entity">
								<span *ngIf="processNodeFacade.fetchOne(entity.processInstance.state) | async as processNode" class="status status--{{ processNode?.color }}">
									{{ processNode?.displayName }}
								</span>
							</td>
						</ng-container>

						<ng-container matColumnDef="prio-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="prio">Priorität</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="prio-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="prio-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-process-priority-article [priority]="entity.processInstance.priority"></babylon-process-priority-article>
							</td>
						</ng-container>

						<ng-container matColumnDef="filter-header">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button (click)="filterExpanded = !filterExpanded" class="{{ filterExpanded ? 'filter-on' : '' }}">
									<mat-icon svgIcon="filter-variant"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-filter">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button matTooltip="Alle Filter zurücksetzen" (click)="entityFacade.resetFilter()" [disabled]="(entityFacade.filterDescriptors$ | async).length == 0">
									<mat-icon svgIcon="close"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-content">
							<td mat-cell *matCellDef="let entity" class="actions-column">
								<ng-container *ngIf="processNodeFacade.fetchOne(entity.processInstance.state) | async as processNode">
									<button mat-icon-button (click)="showPostcheckDialog(entity)" matTooltip="Nachkontrolle" *ngIf="processNode.name == 'ArticlePostcheck' || processNode.name == 'ArticleCommissioned'">
										<mat-icon svgIcon="clipboard-check-outline"></mat-icon>
									</button>
									<button mat-icon-button (click)="showReproduceDialog(entity)" matTooltip="Anfertigung" *ngIf="processNode.name == 'ArticleReproduce'">
										<mat-icon svgIcon="file-certificate-outline"></mat-icon>
									</button>
									<button mat-icon-button (click)="showOrderDialog(entity)" matTooltip="Bestellung" *ngIf="processNode.name == 'ArticleOrder' || processNode.name == 'ArticleOrdered'">
										<mat-icon svgIcon="phone-message-outline"></mat-icon>
									</button>
									<button mat-icon-button (click)="showPickDialog(entity)" matTooltip="Entnahme" *ngIf="processNode.name == 'ArticlePick'">
										<mat-icon svgIcon="newspaper"></mat-icon>
									</button>
									<button mat-icon-button (click)="showHandoverDialog(entity)" matTooltip="Übergabe" *ngIf="processNode.name == 'ArticleHandOver'">
										<mat-icon svgIcon="dolly"></mat-icon>
									</button>
									<button mat-icon-button (click)="showReturnDialog(entity)" matTooltip="Retoure" *ngIf="processNode.name == 'ArticleReturn'">
										<mat-icon svgIcon="dolly"></mat-icon>
									</button>
								</ng-container>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="columnsHeader; sticky: true"></tr>
						<tr mat-header-row class="filter-row" *matHeaderRowDef="columnsFilter; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: columnsContent"></tr>
					</table>
				</div>
				<mat-toolbar>
					<mat-toolbar-row>
						<div class="buttons">
							<babylon-user-autocompletesingle *ngIf="selection.hasValue()" (selected)="changeAssignee($event)" placeholder="Bearbeiter ändern" [optional]="true"> </babylon-user-autocompletesingle>
						</div>
						<div fxFlex></div>
						<span class="total-count">{{ entityFacade.filterCount$ | async }} von {{ entityFacade.totalCount$ | async }} Einträgen</span>
						<button mat-icon-button (click)="entityFacade.filter()" matTooltip="Aktualisieren">
							<mat-icon svgIcon="refresh"></mat-icon>
						</button>
						<button mat-icon-button (click)="entityFacade.increase()" matTooltip="Weitere anzeigen">
							<mat-icon svgIcon="playlist-plus"></mat-icon>
						</button>
					</mat-toolbar-row>
				</mat-toolbar>
			</mat-card>
		</ng-container>
	</ng-container>
</div>
