<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="orderFacade.isFiltering$ | async"></mat-progress-bar>
<ng-container *ngIf="entityFacade.selected$ | async as entity">
	<div class="container entitypage-add">
		<div class="hgroup">
			<div class="headline">
				<h1 class="mat-h1">
					{{ pageTitle$ | async }}
				</h1>
				<div *ngIf="customerFacade.fetchOne(entity.customer) | async as customer">{{ customer.firstName }} {{ customer.lastName }}</div>
				<ng-container *ngIf="receiptFacade.fetchOne(entity.primaryReceipt) | async as receipt">
					<div>{{ receipt.receiver.address.street }} {{ receipt.receiver.address.streetNumber }}, {{ receipt.receiver.address.postCode }} {{ receipt.receiver.address.city }}, {{ receipt.receiver.address.country }}</div>

					<div *ngIf="shippingFacade.fetchMany(entity.shippings) | async as shippings">
						<ng-container *ngIf="shippings.length > 0 && deliveryNoteFacade.fetchOne(shippings[shippings.length - 1].deliveryNote) | async as deliveryNote">
							<div
								*ngIf="
									receipt.receiver.address.street != deliveryNote.receiver.address.street ||
									receipt.receiver.address.streetNumber != deliveryNote.receiver.address.streetNumber ||
									receipt.receiver.address.postCode != deliveryNote.receiver.address.postCode ||
									receipt.receiver.address.city != deliveryNote.receiver.address.city ||
									receipt.receiver.address.country != deliveryNote.receiver.address.country
								">
								Lieferadresse: {{ deliveryNote.receiver.address.street }} {{ deliveryNote.receiver.address.streetNumber }}, {{ deliveryNote.receiver.address.postCode }} {{ deliveryNote.receiver.address.city }},
								{{ deliveryNote.receiver.address.country }}
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>

			<div>
				<ng-container>Eingegangen am: {{ entity.createdAt | amDateFormat: 'DD.MM.YYYY HH:mm (dd)' }} </ng-container>
				<ng-container *ngIf="entity.ipInfo"><br />IP: {{ entity.ipInfo.ip }} ({{ entity.ipInfo.city }}) </ng-container>
			</div>

			<div *ngIf="currentDate$ | async as currentDate" class="datewrapper">
				<div class="date">{{ currentDate | amDateFormat: 'dd, DD.MM.YYYY' }}</div>
				<div class="time">{{ currentDate | amDateFormat: 'HH:mm' }} Uhr</div>
				<div class="status-row">
					<ng-container *ngIf="receiptFacade.fetchOne(entity.primaryReceipt) | async as receipt">
						<babylon-process-state matTooltip="Belegstatus" [entity]="receipt" [facade]="receiptFacade"></babylon-process-state>
					</ng-container>
					<babylon-process-priority-order matTooltip="Priorität" [priority]="entity.processInstance.priority"></babylon-process-priority-order>
				</div>
			</div>
		</div>

		<div class="content-wrapper first" *ngIf="!entity.partner">
			<h2 class="mat-h4 headline-flex">
				<div>Kunde <mat-spinner [@progressFade] diameter="15" *ngIf="customerFacade.isFetching$ | async"></mat-spinner></div>
			</h2>
			<ng-container *ngIf="customerFacade.fetchOne(entity.customer) | async as customer">
				<table class="mat-table">
					<tr class="mat-header-row">
						<th class="mat-header-cell">Kundennummer</th>
						<th class="mat-header-cell">Name</th>
						<th class="mat-header-cell">Telefonnummer</th>
						<th class="mat-header-cell">E-Mail</th>
						<th class="mat-header-cell"></th>
					</tr>
					<tr class="mat-row">
						<td class="mat-cell">{{ customer.number }}</td>
						<td class="mat-cell">{{ customer.salutationKind }} {{ customer.firstName }} {{ customer.lastName }}</td>
						<td class="mat-cell">{{ customer.telephone }}</td>
						<td class="mat-cell">{{ customer.email }}</td>
						<td class="mat-cell align-right">
							<button mat-icon-button matTooltip="Bearbeiten">
								<mat-icon svgIcon="pencil" (click)="customerDialogService.openUpdateDialog({ entity: customer })"></mat-icon>
							</button>
						</td>
					</tr>
				</table>
			</ng-container>
		</div>

		<div class="content-wrapper" *ngIf="entity.partner">
			<h2 class="mat-h4">Partner <mat-spinner [@progressFade] diameter="15" *ngIf="partnerFacade.isFetching$ | async"></mat-spinner></h2>
			<ng-container *ngIf="partnerFacade.fetchOne(entity.partner) | async as partner">
				<table class="mat-table">
					<tr class="mat-header-row">
						<th class="mat-header-cell">Name</th>
						<th class="mat-header-cell">Rechnungsempfänger</th>
						<th class="mat-header-cell">Telefonnummer</th>
						<th class="mat-header-cell">E-Mail</th>
					</tr>
					<tr class="mat-row">
						<td class="mat-cell">{{ partner.name }}</td>
						<td class="mat-cell">
							{{ partner.receiver.salutationKind }} {{ partner.receiver.firstName }} {{ partner.receiver.lastName }} <ng-container *ngIf="partner.receiver.company">({{ partner.receiver.company }})</ng-container>
						</td>
						<td class="mat-cell">{{ partner.telephone }}</td>
						<td class="mat-cell">{{ partner.email }}</td>
					</tr>
				</table>
			</ng-container>
		</div>

		<div class="content-wrapper">
			<h2 class="mat-h4 headline-flex">
				<div>Positionen &amp; Artikel <mat-spinner [@progressFade] diameter="15" *ngIf="(articleFacade.isFetching$ | async) || (articleFacade.isFiltering$ | async)"> </mat-spinner></div>
				<button mat-button (click)="editPositions(entity)" color="accent"><mat-icon svgIcon="pencil"></mat-icon> Positionen bearbeiten</button>
			</h2>
			<table mat-table [dataSource]="orderFacade.positions$ | async" [trackBy]="trackByEntity" multiTemplateDataRows class="detail-table">
				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef>Position/Artikel</th>
					<td mat-cell *matCellDef="let position" class="detail-header">
						{{ position.title }}
					</td>
				</ng-container>

				<ng-container matColumnDef="price">
					<th mat-header-cell *matHeaderCellDef>Preis</th>
					<td mat-cell *matCellDef="let position" class="detail-header" width="100">
						{{ position.price | currency }}
					</td>
				</ng-container>

				<ng-container matColumnDef="currentUser">
					<th mat-header-cell *matHeaderCellDef class="align-right">Bearbeiter</th>
					<td mat-cell *matCellDef="let position" class="detail-header align-right" width="200"></td>
				</ng-container>

				<ng-container matColumnDef="state">
					<th mat-header-cell *matHeaderCellDef class="align-right">Status</th>
					<td mat-cell *matCellDef="let position" class="detail-header align-right" width="200"></td>
				</ng-container>

				<ng-container matColumnDef="priority">
					<th mat-header-cell *matHeaderCellDef class="align-right">Priorität</th>
					<td mat-cell *matCellDef="let position" class="detail-header align-right" width="150"></td>
				</ng-container>

				<ng-container matColumnDef="articles">
					<td [attr.colspan]="positionColumns.length" mat-cell *matCellDef="let position" class="detail-cell">
						<table mat-table [dataSource]="orderFacade.getArticlesFromPosition(position) | async" [trackBy]="trackByEntity">
							<ng-container matColumnDef="image">
								<td mat-cell *matCellDef="let position" width="80"></td>
							</ng-container>

							<ng-container matColumnDef="title">
								<td mat-cell *matCellDef="let article">
									{{ article.product.name }}
									<ng-container *ngIf="article.publicationDate">vom {{ article.publicationDate | amDateFormat: 'DD.MM.YYYY (dd)' }}</ng-container>
									<ng-container *ngIf="articleFacade.fetchOne(article._id) | async | asCertificate as certificate">
										für {{ certificate.giftee }}<ng-container *ngIf="certificate.partner"> und {{ certificate.partner }}</ng-container> am {{ certificate.date | amDateFormat: 'DD.MM.YYYY (dd)' }}
									</ng-container>
								</td>
							</ng-container>

							<ng-container matColumnDef="price">
								<td mat-cell *matCellDef="let article" width="100">
									{{ article.product.price | currency }}
								</td>
							</ng-container>

							<ng-container matColumnDef="currentUser">
								<td mat-cell *matCellDef="let article" class="align-right" width="200">
									<ng-container *ngIf="article._id && articleFacade.fetchOne(article._id) | async as article">
										<babylon-profile-picture [userId]="article.processInstance.currentUser"> </babylon-profile-picture>
										<babylon-fetch [facade]="userFacade" [value]="article.processInstance.currentUser"></babylon-fetch>
									</ng-container>
								</td>
							</ng-container>

							<ng-container matColumnDef="state">
								<td mat-cell *matCellDef="let article" class="align-right" width="200">
									<ng-container *ngIf="article._id && articleFacade.fetchOne(article._id) | async as article">
										<babylon-process-state [entity]="article" [facade]="articleFacade"></babylon-process-state>
										<babylon-fetch [facade]="processNodeFacade" [value]="article.processInstance.state"></babylon-fetch>
									</ng-container>
									<ng-container>
										<span *ngIf="!article._id" class="status status--red"> Auszutauschen </span>
									</ng-container>
								</td>
							</ng-container>

							<ng-container matColumnDef="priority">
								<td mat-cell *matCellDef="let article" class="align-right" width="150">
									<ng-container *ngIf="article._id && articleFacade.fetchOne(article._id) | async as article">
										<babylon-process-priority-article [priority]="article.processInstance.priority"></babylon-process-priority-article>
									</ng-container>
								</td>
							</ng-container>

							<ng-container matColumnDef="actions">
								<td mat-cell *matCellDef="let article" class="align-right" width="250">
									<ng-container *ngIf="article._id && articleFacade.fetchOne(article._id) | async as article">
										<button mat-icon-button matTooltip="Drucken" (click)="certificateFacade.print(article)" *ngIf="article.articleKind == ArticleKinds.Certificate" [disabled]="certificateFacade.isFetching$ | async">
											<mat-icon svgIcon="printer-outline"></mat-icon>
										</button>
										<button mat-icon-button matTooltip="Herunterladen" (click)="certificateFacade.download(article)" *ngIf="article.articleKind == ArticleKinds.Certificate">
											<mat-icon svgIcon="file-download-outline"></mat-icon>
										</button>
										<babylon-process-buttons [facade]="articleFacade" [entity]="article" mode="icon" (executed)="entityFacade.invalidate(entity)"></babylon-process-buttons>
									</ng-container>
								</td>
							</ng-container>

							<tr mat-row *matRowDef="let row; columns: positionColumns" [@fadeInOut]></tr>
						</table>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef class="align-right"></th>
					<td mat-cell *matCellDef="let position" class="detail-header align-right" width="250"></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="positionColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: positionColumns" [@fadeInOut]></tr>
				<tr mat-row *matRowDef="let row; columns: ['articles']" [@fadeInOut] class="detail-row" [hidden]="row.positionKind != PositionKinds.Article && row.positionKind != PositionKinds.Bundle"></tr>
			</table>
		</div>

		<div class="content-wrapper">
			<h2 class="mat-h4 headline-flex">
				<div>
					Nachrichten & Kommentare
					<mat-spinner diameter="15" *ngIf="(messageFacade.isFetching$ | async) || (messageFacade.isFiltering$ | async) || (attachmentFacade.isFetching$ | async) || (uploadedFileFacade.isFetching$ | async)"> </mat-spinner>
				</div>
				<button mat-button (click)="addMessage(entity)" color="accent"><mat-icon svgIcon="pencil"></mat-icon> Nachricht hinzufügen</button>
			</h2>

			<table mat-table [dataSource]="messageFacade.fetchMany(entity.messages) | async" [trackBy]="trackByEntity" multiTemplateDataRows>
				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef>Titel</th>
					<td mat-cell *matCellDef="let message" class="detail-header pointer">
						<div class="attachment-wrapper">
							<span (click)="updateMessage(message, entity)">{{ message.title }}</span>
							<ng-container *ngFor="let attachment of attachmentFacade.fetchMany(message.attachments) | async">
								<a class="attachment" [href]="uploadedFile.url" target="_blank" *ngIf="uploadedFileFacade.fetchOne(attachment.file) | async as uploadedFile">
									{{ attachment.title }}
								</a>
							</ng-container>
						</div>
					</td>
				</ng-container>

				<ng-container matColumnDef="state">
					<th mat-header-cell *matHeaderCellDef class="align-right">Status</th>
					<td mat-cell *matCellDef="let message" class="detail-header align-right" width="200">
						<babylon-process-state [entity]="message" [facade]="messageFacade"></babylon-process-state>
						<babylon-fetch [facade]="processNodeFacade" [value]="message.processInstance.state"> </babylon-fetch>
					</td>
				</ng-container>

				<ng-container matColumnDef="prio">
					<th mat-header-cell *matHeaderCellDef class="align-right">Priorität</th>
					<td mat-cell *matCellDef="let message" class="detail-header align-right" width="200">
						<babylon-process-priority-message [message]="message"></babylon-process-priority-message>
					</td>
				</ng-container>

				<ng-container matColumnDef="currentUser">
					<th mat-header-cell *matHeaderCellDef class="align-right"></th>
					<td mat-cell *matCellDef="let message" class="detail-header align-right" width="300">
						<small>{{ message.updatedBy ?? message.createdBy }} am {{ message.updatedAt | amDateFormat: 'DD.MM.YYYY HH:mm' }}</small>
						<babylon-fetch [facade]="userFacade" [value]="message.processInstance.currentUser"> </babylon-fetch>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let message" class="detail-header align-right" width="50">
						<babylon-process-buttons [facade]="messageFacade" [entity]="message" mode="icon" (executed)="entityFacade.invalidate(entity)"></babylon-process-buttons>
						<button mat-icon-button (click)="removeMessage(message, entity)" matTooltip="Löschen" [disabled]="message.readonly">
							<mat-icon svgIcon="delete"></mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="messageColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: messageColumns" class="priority-{{ row.processInstance.priority }}" [@fadeInOut]></tr>
			</table>
		</div>

		<div class="content-wrapper">
			<h2 class="mat-h4 headline-flex">
				<div>
					Belege &amp; Zahlungen
					<mat-spinner diameter="15" *ngIf="(receiptFacade.isFetching$ | async) || (receiptFacade.isFiltering$ | async) || (paymentFacade.isFetching$ | async) || (paymentFacade.isFiltering$ | async)"> </mat-spinner>
				</div>
				<button *ngIf="receiptFacade.fetchOne(entity.primaryReceipt) | async as primaryReceipt" mat-button (click)="editReceiver(primaryReceipt, entity)" color="accent">
					<mat-icon svgIcon="pencil"></mat-icon> Belegempfänger bearbeiten
				</button>
			</h2>

			<table mat-table [dataSource]="receiptFacade.fetchMany(allReceipts(entity.primaryReceipt, entity.secondaryReceipts)) | async" [trackBy]="trackByEntity" multiTemplateDataRows class="detail-table">
				<ng-container matColumnDef="receiptKind">
					<th mat-header-cell *matHeaderCellDef>Belegtyp/Zahlart</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header">{{ receipt.receiptKind }}</td>
				</ng-container>

				<ng-container matColumnDef="number">
					<th mat-header-cell *matHeaderCellDef>Belegnummer</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header">{{ receipt.number }}</td>
				</ng-container>

				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef>Belegdatum</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header">
						<ng-container *ngIf="receipt.date">{{ receipt.date | amLocal | amDateFormat: 'DD.MM.YYYY (dd)' }}</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef="dueDate">
					<th mat-header-cell *matHeaderCellDef>Fällig am</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header">
						<ng-container *ngIf="receipt.dueDate">
							{{ receipt.dueDate | amLocal | amDateFormat: 'DD.MM.YYYY (dd)' }}
							<ng-container *ngIf="receipt.dueDateDelayed && receipt.dueDateDelayed != receipt.dueDate"> &rarr; {{ receipt.dueDateDelayed | amLocal | amDateFormat: 'DD.MM.YYYY (dd)' }}</ng-container>
						</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef="totalPrice">
					<th mat-header-cell *matHeaderCellDef>Betrag</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header" width="100">{{ receipt.totalPrice | currency }}</td>
				</ng-container>

				<ng-container matColumnDef="consentEmailTransfer">
					<th mat-header-cell *matHeaderCellDef>E-Mailversand</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header" width="110">
						<mat-slide-toggle color="primary" [checked]="receipt.consentEmailTransfer" (change)="changeReceiptConsentEmailTransfer($event, receipt)"></mat-slide-toggle>
					</td>
				</ng-container>

				<ng-container matColumnDef="currentUser">
					<th mat-header-cell *matHeaderCellDef class="align-right">Bearbeiter</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header align-right" width="200">
						<babylon-profile-picture [userId]="receipt.processInstance.currentUser"> </babylon-profile-picture>
						<babylon-fetch [facade]="userFacade" [value]="receipt.processInstance.currentUser"> </babylon-fetch>
					</td>
				</ng-container>

				<ng-container matColumnDef="state">
					<th mat-header-cell *matHeaderCellDef class="align-right">Status</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header align-right" width="200">
						<babylon-process-state [entity]="receipt" [facade]="receiptFacade"></babylon-process-state>
						<babylon-fetch [facade]="processNodeFacade" [value]="receipt.processInstance.state"> </babylon-fetch>
					</td>
				</ng-container>

				<ng-container matColumnDef="payments">
					<td [attr.colspan]="receiptColumns.length" mat-cell *matCellDef="let receipt" class="detail-cell">
						<table mat-table [dataSource]="paymentFacade.fetchMany(receipt.payments) | async" [trackBy]="trackByEntity">
							<ng-container matColumnDef="paymentKind">
								<td mat-cell *matCellDef="let payment">
									{{ payment.paymentKind }}
								</td>
							</ng-container>

							<ng-container matColumnDef="dueAmount">
								<td mat-cell *matCellDef="let payment" width="210">{{ payment.dueAmount | currency }}</td>
							</ng-container>

							<ng-container matColumnDef="currentUser">
								<td mat-cell *matCellDef="let payment" class="align-right" width="200">
									<babylon-profile-picture [userId]="payment.processInstance.currentUser"> </babylon-profile-picture>
									<babylon-fetch [facade]="userFacade" [value]="payment.processInstance.currentUser"> </babylon-fetch>
								</td>
							</ng-container>

							<ng-container matColumnDef="state">
								<td mat-cell *matCellDef="let payment" class="align-right" width="200">
									<babylon-process-state [entity]="payment" [facade]="paymentFacade"></babylon-process-state>
									<babylon-fetch [facade]="processNodeFacade" [value]="payment.processInstance.state"> </babylon-fetch>
								</td>
							</ng-container>

							<ng-container matColumnDef="actions">
								<td mat-cell *matCellDef="let payment" class="align-right" width="250">
									<ng-container *ngIf="processNodeFacade.fetchOne(payment.processInstance.state) | async as processNode">
										<babylon-process-buttons [facade]="paymentFacade" [entity]="payment" mode="icon" (executed)="entityFacade.invalidate(entity)"></babylon-process-buttons>
										<button
											mat-icon-button
											matTooltip="Zahlungslink versenden"
											*ngIf="payment.paymentKind == 'Paypal'"
											(click)="paymentFacade.sendPaymentLink(payment)"
											[disabled]="(paymentFacade.isFetching$ | async) || processNode.name != 'PaymentAssigned'">
											<mat-icon svgIcon="email-fast-outline"></mat-icon>
										</button>
										<button
											mat-icon-button
											matTooltip="Zahlungsweise ändern"
											(click)="paymentDialogService.openChangePaymentKindDialog({ entity: payment })"
											[disabled]="(paymentFacade.isFetching$ | async) || processNode.name != 'PaymentAssigned'">
											<mat-icon svgIcon="credit-card-refresh-outline"></mat-icon>
										</button>
										<button mat-icon-button (click)="paymentDialogService.openUpdateDialog({ entity: payment })" matTooltip="Bearbeiten">
											<mat-icon svgIcon="pencil"></mat-icon>
										</button>
									</ng-container>
								</td>
							</ng-container>

							<tr mat-row *matRowDef="let row; columns: paymentColumns" [@fadeInOut]></tr>
						</table>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let receipt" class="detail-header align-right" width="250">
						<babylon-process-buttons [facade]="receiptFacade" [entity]="receipt" mode="icon" (executed)="entityFacade.invalidate(entity)"></babylon-process-buttons>
						<button mat-icon-button matTooltip="Drucken" (click)="receiptFacade.print(receipt)" [disabled]="receiptFacade.isFetching$ | async">
							<mat-icon svgIcon="printer-outline"></mat-icon>
						</button>
						<button mat-icon-button matTooltip="Herunterladen" [matMenuTriggerFor]="receiptMenu">
							<mat-icon svgIcon="file-download-outline"></mat-icon>
						</button>
						<mat-menu #receiptMenu="matMenu">
							<button mat-menu-item (click)="receiptFacade.download(receipt)">Beleg</button>
							<button mat-menu-item (click)="receiptFacade.downloadFirstReminder(receipt)">Zahlungserinnerung</button>
							<button mat-menu-item (click)="receiptFacade.downloadSecondReminder(receipt)">Mahnung</button>
						</mat-menu>
						<button mat-icon-button (click)="receiptDialogService.openUpdateDialog({ entity: receipt })" matTooltip="Bearbeiten">
							<mat-icon svgIcon="pencil"></mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="receiptColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: receiptColumns" [@fadeInOut]></tr>
				<tr mat-row *matRowDef="let row; columns: ['payments']" [@fadeInOut] class="detail-row"></tr>
			</table>
		</div>

		<div class="content-wrapper">
			<h2 class="mat-h4">
				Lieferungen & Lieferscheine
				<mat-spinner [@progressFade] diameter="15" *ngIf="(shippingFacade.isFetching$ | async) || (deliveryNoteFacade.isFetching$ | async) || (shippingFacade.isFiltering$ | async) || (deliveryNoteFacade.isFiltering$ | async)">
				</mat-spinner>
			</h2>
			<table mat-table [dataSource]="shippingFacade.fetchMany(entity.shippings) | async" [trackBy]="trackByEntity" multiTemplateDataRows class="detail-table">
				<ng-container matColumnDef="shippingKind">
					<th mat-header-cell *matHeaderCellDef>Lieferart/Lieferschein</th>
					<td mat-cell *matCellDef="let shipping" class="detail-header">{{ shipping.shippingKind }}</td>
				</ng-container>

				<ng-container matColumnDef="shippingByPartner">
					<th mat-header-cell *matHeaderCellDef>Versand durch Partner</th>
					<td mat-cell *matCellDef="let shipping" class="detail-header" width="200">
						<ng-container *ngIf="processNodeFacade.fetchOne(shipping.processInstance.state) | async as processNode">
							<mat-slide-toggle color="primary" [checked]="shipping.shippingByPartner" [disabled]="processNode.name == 'ShippingShipped'" (change)="changeShippingByPartner($event, shipping)"></mat-slide-toggle>
						</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef="consentEmailTransfer">
					<th mat-header-cell *matHeaderCellDef>Statusbenachrichtigung</th>
					<td mat-cell *matCellDef="let shipping" class="detail-header" width="200">
						<ng-container *ngIf="processNodeFacade.fetchOne(shipping.processInstance.state) | async as processNode">
							<mat-slide-toggle color="primary" [checked]="shipping.consentEmailTransfer" [disabled]="processNode.name == 'ShippingShipped'" (change)="changeShippingConsentEmailTransfer($event, shipping)"></mat-slide-toggle>
						</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef="enableSaturdayDelivery">
					<th mat-header-cell *matHeaderCellDef>Samstagszustellung</th>
					<td mat-cell *matCellDef="let shipping" class="detail-header" width="200">
						<ng-container *ngIf="processNodeFacade.fetchOne(shipping.processInstance.state) | async as processNode">
							<mat-slide-toggle
								color="primary"
								[checked]="shipping.enableSaturdayDelivery"
								[disabled]="shipping.shippingKind != ShippingKinds.DhlExpress || processNode.name == 'ShippingShipped' || (processNode.name == 'ShippingCommissioned' && !(sessionFacade.administrateProcessRoutes$ | async))"
								(change)="changeEnableSaturdayDelivery($event, shipping)"></mat-slide-toggle>
						</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef="currentUser">
					<th mat-header-cell *matHeaderCellDef class="align-right">Bearbeiter</th>
					<td mat-cell *matCellDef="let shipping" class="detail-header align-right" width="200">
						<babylon-profile-picture [userId]="shipping.processInstance.currentUser"> </babylon-profile-picture>
						<babylon-fetch [facade]="userFacade" [value]="shipping.processInstance.currentUser"> </babylon-fetch>
					</td>
				</ng-container>

				<ng-container matColumnDef="state">
					<th mat-header-cell *matHeaderCellDef class="align-right">Status</th>
					<td mat-cell *matCellDef="let shipping" class="detail-header align-right" width="200">
						<babylon-process-state [entity]="shipping" [facade]="shippingFacade"></babylon-process-state>
						<babylon-fetch [facade]="processNodeFacade" [value]="shipping.processInstance.state"> </babylon-fetch>
					</td>
				</ng-container>

				<ng-container matColumnDef="deliveryNotes">
					<td [attr.colspan]="shippingColumns.length" mat-cell *matCellDef="let shipping" class="detail-cell">
						<table mat-table [dataSource]="deliveryNoteFacade.fetchMany([shipping.deliveryNote]) | async" [trackBy]="trackByEntity">
							<ng-container matColumnDef="deliveryNoteKind">
								<td mat-cell *matCellDef="let deliveryNote">Lieferschein</td>
							</ng-container>

							<ng-container matColumnDef="receiver">
								<td mat-cell *matCellDef="let deliveryNote">
									{{ deliveryNote.receiver.salutationKind }} {{ deliveryNote.receiver.firstName }} {{ deliveryNote.receiver.lastName }},
									<ng-container *ngIf="deliveryNote.receiver.company">{{ deliveryNote.receiver.company }}, </ng-container> {{ deliveryNote.receiver.address.street }} {{ deliveryNote.receiver.address.streetNumber }},
									{{ deliveryNote.receiver.address.postCode }} {{ deliveryNote.receiver.address.city }}, {{ deliveryNote.receiver.address.country }}
									<span *ngIf="shipping.shippingWithoutInvoice" class="status status--red">Rechnung nicht beilegen</span>
									<span *ngIf="!shipping.shippingWithoutInvoice" class="status status--green">Rechnung beilegen</span>
								</td>
							</ng-container>

							<ng-container matColumnDef="email">
								<td mat-cell *matCellDef="let deliveryNote">
									{{ deliveryNote.receiver.email }}
								</td>
							</ng-container>

							<ng-container matColumnDef="telephone">
								<td mat-cell *matCellDef="let deliveryNote">
									{{ deliveryNote.receiver.telephone }}
								</td>
							</ng-container>

							<ng-container matColumnDef="actions">
								<td mat-cell *matCellDef="let deliveryNote" class="align-right" width="250">
									<button mat-icon-button matTooltip="Drucken" (click)="deliveryNoteFacade.print(deliveryNote)" [disabled]="receiptFacade.isFetching$ | async">
										<mat-icon svgIcon="printer-outline"></mat-icon>
									</button>
									<button mat-icon-button matTooltip="Herunterladen" (click)="deliveryNoteFacade.download(deliveryNote)">
										<mat-icon svgIcon="file-download-outline"></mat-icon>
									</button>
									<ng-container *ngIf="processNodeFacade.fetchOne(shipping.processInstance.state) | async as shippingProcessNode">
										<button mat-icon-button (click)="deliveryNoteDialogService.openUpdateDialog({ entity: deliveryNote })" matTooltip="Bearbeiten">
											<mat-icon svgIcon="pencil"></mat-icon>
										</button>
									</ng-container>
								</td>
							</ng-container>

							<tr mat-row *matRowDef="let row; columns: deliveryNoteColumns" [@fadeInOut]></tr>
						</table>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let shipping" class="detail-header align-right" width="250">
						<babylon-process-buttons [facade]="shippingFacade" [entity]="shipping" mode="icon" (executed)="entityFacade.invalidate(entity)"></babylon-process-buttons>
						<ng-container *ngIf="processNodeFacade.fetchOne(shipping.processInstance.state) | async as processNode">
							<button
								*ngIf="shipping.shippingKind != 'Retourenlieferung'"
								mat-icon-button
								matTooltip="Liefermethode ändern"
								(click)="shippingDialogService.openChangeShippingKindDialog({ entity: shipping })"
								[disabled]="(shippingFacade.isFetching$ | async) || (processNode.name != 'ShippingInitialized' && processNode.name != 'ShippingPrepared' && processNode.name != 'ShippingCommissioned')">
								<mat-icon svgIcon="archive-refresh-outline"></mat-icon>
							</button>
							<button
								mat-icon-button
								matTooltip="Drucken"
								(click)="shippingFacade.print(shipping)"
								[disabled]="(processNode.name != 'ShippingCommissioned' && processNode.name != 'ShippingShipped') || (shippingFacade.isFetching$ | async)">
								<mat-icon svgIcon="printer-outline"></mat-icon>
							</button>
							<button mat-icon-button matTooltip="Herunterladen" (click)="shippingFacade.download(shipping)" [disabled]="processNode.name != 'ShippingCommissioned' && processNode.name != 'ShippingShipped'">
								<mat-icon svgIcon="file-download-outline"></mat-icon>
							</button>
						</ng-container>
						<button mat-icon-button (click)="shippingDialogService.openUpdateDialog({ entity: shipping })" matTooltip="Bearbeiten">
							<mat-icon svgIcon="pencil"></mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="shippingColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: shippingColumns" [@fadeInOut]></tr>
				<tr mat-row *matRowDef="let row; columns: ['deliveryNotes']" [@fadeInOut] class="detail-row"></tr>
			</table>
		</div>

		<mat-toolbar class="sticky" [class.drawer-opened]="menuFacade.isMenuExpanded$ | async">
			<mat-toolbar-row>
				<button mat-button color="default" (click)="orderDialogService.openPreviewDialog({ entity })">Auftragshistorie</button>
				<button mat-button (click)="duplicate(entity)" color="accent"><mat-icon svgIcon="content-duplicate"></mat-icon> Auftrag duplizieren</button>
				<button mat-button (click)="sendOrderConfirmation()" color="accent"><mat-icon svgIcon="email-arrow-right-outline"></mat-icon> Bestellbestätigung senden</button>
				<ng-container *ngIf="processNodeFacade.fetchOne(entity.processInstance.state) | async as processNode">
					<ng-container *ngIf="processRouteFacade.findBySourceNode(entity.processInstance.state, 'Zurückstellen') | async as processRoutes">
						<button mat-button *ngIf="processNode.name == 'OrderApproved' || processNode.name == 'OrderVerified' || processNode.name == 'OrderAssigned'" (click)="openPostponeDialog(entity, processRoutes[0])" color="accent">
							<mat-icon svgIcon="history"></mat-icon> Zurückstellen
						</button>
					</ng-container>
				</ng-container>
				<span fxFlex></span>
				<ng-container *ngIf="processNodeFacade.fetchOne(entity.processInstance.state) | async as processNode">
					<button mat-flat-button color="default" (click)="orderDialogService.openRetourePositionsDialog()" *ngIf="processNode.name == 'OrderCompleted'">Auftragsretoure</button>
				</ng-container>
				<babylon-process-buttons [facade]="entityFacade" (executed)="onExecuted($event)" [entity]="entity"> </babylon-process-buttons>
			</mat-toolbar-row>
		</mat-toolbar>
	</div>
</ng-container>
