<ng-container>
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">{{ data.title }}</h1>
	<mat-dialog-content>
		<form [formGroup]="formGroup">
			<mat-form-field appearance="outline" class="resubmission-date">
				<mat-label>Datum der Wiedervorlage</mat-label>
				<input matInput #dateInput [matDatepicker]="resubmissionDate" formControlName="resubmissionDate" />
				<mat-datepicker-toggle matSuffix [for]="resubmissionDate"></mat-datepicker-toggle>
				<mat-datepicker #resubmissionDate></mat-datepicker>
			</mat-form-field>
		</form>
	</mat-dialog-content>

	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="data.onReject()">{{ data.rejectButtonText ?? 'Schließen' }}</button>
		<button mat-flat-button color="accent" (click)="data.onConfirm(formGroup.value)" [disabled]="!formGroup.valid">{{ data.confirmButtonText ?? 'Okay' }}</button>
	</mat-dialog-actions>
</ng-container>
