import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProcessRoute } from '../../models/process-route.model';
import { IStock, StockKind } from '../../models/stock.model';
import { EntityApiService, IEntitySingleResponse } from './entity.service';
import { StockAccessoryApiService } from './stock.accessory.service';
import { StockMediaApiService } from './stock.media.service';

@Injectable({
	providedIn: 'root',
})
export class StockApiService extends EntityApiService<IStock> {
	protected entityName = 'stocks';

	constructor(http: HttpClient, private accessoryStockService: StockAccessoryApiService, private mediaStockService: StockMediaApiService) {
		super(http);
	}

	public changeProcess(entity: IStock, processRoute: IProcessRoute, comment: string, parameters: Record<string, any>, authToken: string): Observable<IEntitySingleResponse<IStock>> {
		switch (entity.stockKind) {
			case StockKind.StockAccessory:
				return this.accessoryStockService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case StockKind.StockMedia:
				return this.mediaStockService.changeProcess(entity, processRoute, comment, parameters, authToken);
		}
	}

	public revertProcess(entity: IStock, comment: string, authToken: string): Observable<IEntitySingleResponse<IStock>> {
		switch (entity.stockKind) {
			case StockKind.StockAccessory:
				return this.accessoryStockService.revertProcess(entity, comment, authToken);
			case StockKind.StockMedia:
				return this.mediaStockService.revertProcess(entity, comment, authToken);
		}
	}
}
