import { Boxed } from 'ngrx-forms';
import { IEntity, IEntityEditForm, IEntityFilterForm, IExpandedEntityEditForm } from './entity.model';
import { IOrder } from './order.model';
import { IExpandedProductEditForm, IProduct } from './product.model';
import { IStock } from './stock.model';
import { IStoragePlace } from './storage-place.model';

export interface IArticle extends IEntity {
	articleKind: ArticleKind;
	quality: number;
	publicationDate: string;
	product: string;
	storagePlace: string;
	stock: string;
	order: string;
}

export interface IExpandedArticle extends IEntity {
	articleKind: ArticleKind;
	quality: number;
	publicationDate: string;
	product: IProduct;
	storagePlace: IStoragePlace;
	stock: IStock;
	order: IOrder;
}

export interface IArticleEditForm extends IEntityEditForm {
	articleKind: ArticleKind;
	quality: number;
	publicationDate: string;
	product: string;
	storagePlace: string;
	stock: string;
	order: string;
}

export interface IExpandedArticleEditForm extends IExpandedEntityEditForm {
	articleKind: ArticleKind;
	publicationDate: string;
	product: IExpandedProductEditForm;
}

export interface IArticleFilterForm extends IEntityFilterForm {
	articleKind: Boxed<ArticleKind[]>;
	quality: Boxed<number[]>;
	publicationDate: string;
	product: string;
	storagePlace: string;
	stock: string;
	order: string;
}

export interface IMediaArticle extends IArticle {
	publicationDate: string;
}

export interface IMediaArticleEditForm extends IArticleEditForm {
	publicationDate: string;
}

export interface IMediaArticleFilterForm extends IArticleFilterForm {
	publicationDate: string;
}

export interface IExpandedMediaArticle extends IExpandedArticle {
	publicationDate: string;
}

export interface IExpandedAccessoryArticle extends IExpandedArticle {}

export interface IAccessoryArticle extends IArticle {}
export interface IAccessoryArticleEditForm extends IArticleEditForm {}
export interface IExpandedAccessoryArticleEditForm extends IExpandedArticleEditForm {}
export interface IAccessoryArticleFilterForm extends IArticleFilterForm {}

export interface IArticleBook extends IMediaArticle {}
export interface IArticleBookEditForm extends IMediaArticleEditForm {}
export interface IBookFilterForm extends IMediaArticleFilterForm {}
export interface IExpandedArticleBook extends IExpandedMediaArticle {}

export interface IArticleCd extends IMediaArticle {}
export interface IArticleCdEditForm extends IMediaArticleEditForm {}
export interface IArticleCdFilterForm extends IMediaArticleFilterForm {}
export interface IExpandedArticleCd extends IExpandedMediaArticle {}

export interface IArticleDvd extends IMediaArticle {}
export interface IArticleDvdEditForm extends IMediaArticleEditForm {}
export interface IArticleDvdFilterForm extends IMediaArticleFilterForm {}
export interface IExpandedArticleDvd extends IExpandedMediaArticle {}

export interface IArticleCoin extends IMediaArticle {}
export interface IArticleCoinEditForm extends IMediaArticleEditForm {}
export interface IArticleCoinFilterForm extends IMediaArticleFilterForm {}
export interface IExpandedArticleCoin extends IExpandedMediaArticle {}

export interface IArticleNewspaper extends IMediaArticle {
	pdfLink: string;
}
export interface IArticleNewspaperEditForm extends IMediaArticleEditForm {
	pdfLink: string;
}
export interface IArticleNewspaperFilterForm extends IMediaArticleFilterForm {
	pdfLink: string;
	state: string;
}
export interface IExpandedArticleNewspaper extends IExpandedMediaArticle {
	pdfLink: string;
}

export interface IArticleMagazine extends IMediaArticle {}
export interface IArticleMagazineEditForm extends IMediaArticleEditForm {}
export interface IArticleMagazineFilterForm extends IMediaArticleFilterForm {}
export interface IExpandedArticleMagazine extends IExpandedMediaArticle {}

export interface IArticleChronicle extends IMediaArticle {}
export interface IArticleChronicleEditForm extends IMediaArticleEditForm {}
export interface IArticleChronicleFilterForm extends IMediaArticleFilterForm {}
export interface IExpandedArticleChronicle extends IExpandedMediaArticle {}

export interface IArticleYearBook extends IMediaArticle {}
export interface IArticleYearBookEditForm extends IMediaArticleEditForm {}
export interface IArticleYearBookFilterForm extends IMediaArticleFilterForm {}
export interface IArticleExpandedYearBook extends IExpandedMediaArticle {}

export interface IArticleBirthdayBook extends IMediaArticle {}
export interface IArticleBirthdayBookEditForm extends IMediaArticleEditForm {}
export interface IArticleBirthdayBookFilterForm extends IMediaArticleFilterForm {}
export interface IExpandedArticleBirthdayBook extends IExpandedMediaArticle {}

export interface IArticleBirthdayChronicle extends IMediaArticle {}
export interface IArticleBirthdayChronicleEditForm extends IMediaArticleEditForm {}
export interface IArticleBirthdayChronicleFilterForm extends IMediaArticleFilterForm {}
export interface IExpandedArticleBirthdayChronicle extends IExpandedMediaArticle {}

export interface IArticleFolder extends IAccessoryArticle {}
export interface IArticleFolderEditForm extends IAccessoryArticleEditForm {}
export interface IArticleFolderFilterForm extends IAccessoryArticleFilterForm {}
export interface IExpandedArticleFolder extends IExpandedAccessoryArticle {}

export interface IArticleStick extends IAccessoryArticle {}
export interface IArticleStickEditForm extends IAccessoryArticleEditForm {}
export interface IArticleStickFilterForm extends IAccessoryArticleFilterForm {}
export interface IExpandedArticleStick extends IExpandedAccessoryArticle {}

export interface IArticleCertificate extends IAccessoryArticle {
	anniversary: number;
	occasion: OccasionType;
	customOccasion: string;
	addressForm: AddressFormType;
	date: string;
	partner: string;
	partnerBirthName: string;
	partnerAdditionalName: string;
	giftee: string;
	gifteeBirthName: string;
	gifteeAdditionalName: string;
	isCustomized: boolean;
	text: string;
	message: string;
	fontSizeOccasion: number;
	fontSizeGiftee: number;
	fontSizeGifteeBirthName: number;
	fontSizePartner: number;
	fontSizePartnerBirthName: number;
	fontSizeText: number;
	fontSizeMessage: number;
}

export interface IExpandedArticleCertificate extends IExpandedAccessoryArticle {
	anniversary: number;
	occasion: OccasionType;
	customOccasion: string;
	addressForm: AddressFormType;
	date: string;
	partner: string;
	partnerBirthName: string;
	partnerAdditionalName: string;
	giftee: string;
	gifteeBirthName: string;
	gifteeAdditionalName: string;
	isCustomized: boolean;
	text: string;
	message: string;
	fontSizeOccasion: number;
	fontSizeGiftee: number;
	fontSizeGifteeBirthName: number;
	fontSizePartner: number;
	fontSizePartnerBirthName: number;
	fontSizeText: number;
	fontSizeMessage: number;
}

export interface IArticleCertificateEditForm extends IAccessoryArticleEditForm {
	anniversary: number;
	occasion: OccasionType;
	customOccasion: string;
	addressForm: AddressFormType;
	date: string;
	partner: string;
	partnerBirthName: string;
	partnerAdditionalName: string;
	giftee: string;
	gifteeBirthName: string;
	gifteeAdditionalName: string;
	isCustomized: boolean;
	text: string;
	message: string;
	fontSizeOccasion: number;
	fontSizeGiftee: number;
	fontSizeGifteeBirthName: number;
	fontSizePartner: number;
	fontSizePartnerBirthName: number;
	fontSizeText: number;
	fontSizeMessage: number;
}

export interface IExpandedArticleCertificateEditForm extends IExpandedAccessoryArticleEditForm {
	anniversary: number;
	occasion: OccasionType;
	customOccasion: string;
	addressForm: AddressFormType;
	date: string;
	partner: string;
	partnerBirthName: string;
	partnerAdditionalName: string;
	giftee: string;
	gifteeBirthName: string;
	gifteeAdditionalName: string;
	isCustomized: boolean;
	text: string;
	message: string;
	fontSizeOccasion: number;
	fontSizeGiftee: number;
	fontSizeGifteeBirthName: number;
	fontSizePartner: number;
	fontSizePartnerBirthName: number;
	fontSizeText: number;
	fontSizeMessage: number;
}

export interface IArticleCertificateFilterForm extends IAccessoryArticleFilterForm {
	anniversary: number;
	occasion: OccasionType;
	customOccasion: string;
	addressForm: AddressFormType;
	date: string;
	partner: string;
	partnerBirthName: string;
	partnerAdditionalName: string;
	giftee: string;
	gifteeBirthName: string;
	gifteeAdditionalName: string;
	isCustomized: boolean;
	text: string;
	message: string;
	fontSizeOccasion: number;
	fontSizeGiftee: number;
	fontSizeGifteeBirthName: number;
	fontSizePartner: number;
	fontSizePartnerBirthName: number;
	fontSizeText: number;
	fontSizeMessage: number;
}

export enum OccasionType {
	Birthday = 'Geburtstag',
	Silver = 'Silberhochzeit',
	Gold = 'Goldene Hochzeit',
	Diamond = 'Diamantene Hochzeit',
	Retirement = 'Ruhestand',
	Christmas = 'Weihnachten',
	Custom = '(Eigener Anlass)',
}

export enum AddressFormType {
	Personal = 'Dein',
	Formally = 'Ihr',
}

export enum ArticleKind {
	BirthdayBook = 'Persönliches Geburtstagsbuch',
	BirthdayChronicle = 'Persönliche Geburtstags-Chronik',
	Book = 'Antiquarisches Buch',
	Coin = 'Historisches Zahlungsmittel',
	Cd = 'CD',
	Dvd = 'DVD',
	Folder = 'Mappe',
	Chronicle = 'Jahrgangs-Chronik',
	Certificate = 'Geschenkurkunde',
	Magazine = 'Magazin',
	Newspaper = 'Zeitung',
	Stick = 'Zeitungshalter',
	YearBook = 'Geschenkbuch',
}
