import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProcessRoute } from '../../models/process-route.model';
import { IProduct, ProductKind } from '../../models/product.model';
import { AccessoryProductApiService } from './accessory-product.service';
import { EntityApiService, IEntitySingleResponse } from './entity.service';
import { MediaProductApiService } from './media-product.service';

@Injectable({
	providedIn: 'root',
})
export class ProductApiService extends EntityApiService<IProduct> {
	protected entityName = 'products';

	constructor(http: HttpClient, private mediaProductService: MediaProductApiService, private accessoryProductService: AccessoryProductApiService) {
		super(http);
	}

	public changeProcess(entity: IProduct, processRoute: IProcessRoute, comment: string, parameters: Record<string, any>, authToken: string): Observable<IEntitySingleResponse<IProduct>> {
		switch (entity.productKind) {
			case ProductKind.AccessoryProduct:
				return this.accessoryProductService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ProductKind.MediaProduct:
				return this.mediaProductService.changeProcess(entity, processRoute, comment, parameters, authToken);
		}
	}

	public revertProcess(entity: IProduct, comment: string, authToken: string): Observable<IEntitySingleResponse<IProduct>> {
		switch (entity.productKind) {
			case ProductKind.AccessoryProduct:
				return this.accessoryProductService.revertProcess(entity, comment, authToken);
			case ProductKind.MediaProduct:
				return this.mediaProductService.revertProcess(entity, comment, authToken);
		}
	}
}
