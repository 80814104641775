import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArticleKind, IArticle } from '../../models/article.model';
import { IProcessRoute } from '../../models/process-route.model';
import { ArticleBirthdayArticleBookApiService } from './article.birthday-book.service';
import { ArticleBirthdayChronicleApiService } from './article.birthday-chronicle.service';
import { ArticleBookApiService } from './article.book.service';
import { ArticleCdApiService } from './article.cd.service';
import { ArticleCertificateApiService } from './article.certificate.service';
import { ArticleChronicleApiService } from './article.chronicle.service';
import { ArticleCoinApiService } from './article.coin.service';
import { ArticleDvdApiService } from './article.dvd.service';
import { ArticleFolderApiService } from './article.folder.service';
import { ArticleMagazineApiService } from './article.magazine.service';
import { ArticleNewspaperApiService } from './article.newspaper.service';
import { ArticleStickApiService } from './article.stick.service';
import { ArticleYearBookApiService } from './article.yearbook.service';
import { EntityApiService, IEntityListResponse, IEntityRequest, IEntitySingleResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleApiService extends EntityApiService<IArticle> {
	protected entityName = 'articles';

	constructor(
		http: HttpClient,
		private bookService: ArticleBookApiService,
		private cdService: ArticleCdApiService,
		private certificateService: ArticleCertificateApiService,
		private birthdayChronicleService: ArticleBirthdayChronicleApiService,
		private birthdayBookService: ArticleBirthdayArticleBookApiService,
		private chronicleService: ArticleChronicleApiService,
		private coinService: ArticleCoinApiService,
		private dvdService: ArticleDvdApiService,
		private folderService: ArticleFolderApiService,
		private magazineService: ArticleMagazineApiService,
		private newspaperService: ArticleNewspaperApiService,
		private yearBookService: ArticleYearBookApiService,
		private stickService: ArticleStickApiService
	) {
		super(http);
	}

	public changeProcess(entity: IArticle, processRoute: IProcessRoute, comment: string, parameters: Record<string, any>, authToken: string): Observable<IEntitySingleResponse<IArticle>> {
		switch (entity.articleKind) {
			case ArticleKind.Book:
				return this.bookService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.Cd:
				return this.cdService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.Certificate:
				return this.certificateService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.Chronicle:
				return this.chronicleService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.Coin:
				return this.coinService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.Dvd:
				return this.dvdService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.Folder:
				return this.folderService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.Magazine:
				return this.magazineService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.Newspaper:
				return this.newspaperService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.Stick:
				return this.stickService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.BirthdayChronicle:
				return this.birthdayChronicleService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.BirthdayBook:
				return this.birthdayBookService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case ArticleKind.YearBook:
				return this.yearBookService.changeProcess(entity, processRoute, comment, parameters, authToken);
		}
	}

	public revertProcess(entity: IArticle, comment: string, authToken: string): Observable<IEntitySingleResponse<IArticle>> {
		switch (entity.articleKind) {
			case ArticleKind.Book:
				return this.bookService.revertProcess(entity, comment, authToken);
			case ArticleKind.Cd:
				return this.cdService.revertProcess(entity, comment, authToken);
			case ArticleKind.Certificate:
				return this.certificateService.revertProcess(entity, comment, authToken);
			case ArticleKind.Chronicle:
				return this.chronicleService.revertProcess(entity, comment, authToken);
			case ArticleKind.Coin:
				return this.coinService.revertProcess(entity, comment, authToken);
			case ArticleKind.Dvd:
				return this.dvdService.revertProcess(entity, comment, authToken);
			case ArticleKind.Folder:
				return this.folderService.revertProcess(entity, comment, authToken);
			case ArticleKind.Magazine:
				return this.magazineService.revertProcess(entity, comment, authToken);
			case ArticleKind.Newspaper:
				return this.newspaperService.revertProcess(entity, comment, authToken);
			case ArticleKind.Stick:
				return this.stickService.revertProcess(entity, comment, authToken);
			case ArticleKind.BirthdayChronicle:
				return this.birthdayChronicleService.revertProcess(entity, comment, authToken);
			case ArticleKind.BirthdayBook:
				return this.birthdayBookService.revertProcess(entity, comment, authToken);
			case ArticleKind.YearBook:
				return this.yearBookService.revertProcess(entity, comment, authToken);
		}
	}

	public updateStoragePlace(entity: IArticle, storagePlaceId: string, authToken: string): Observable<IEntitySingleResponse<IArticle>> {
		return this.get<IEntitySingleResponse<IArticle>>([this.entityName, entity._id, 'storagePlace', storagePlaceId], this.getHeaders(authToken));
	}

	public updateStoragePlaces(request: IEntityRequest<IArticle>, storagePlaceId: string, authToken: string): Observable<IEntityListResponse<IArticle>> {
		return this.post<IEntityListResponse<IArticle>, { request: IEntityRequest<IArticle>; storagePlaceId: string }>([this.entityName, 'changestorageplaces'], { request, storagePlaceId }, this.getHeaders(authToken));
	}

	public deleteFilter(request: IEntityRequest<IArticle>, authToken: string): Observable<IEntityListResponse<IArticle>> {
		return this.post<IEntityListResponse<IArticle>, { request: IEntityRequest<IArticle> }>([this.entityName, 'deletefilter'], { request }, this.getHeaders(authToken));
	}
}
