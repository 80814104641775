import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { APP_BASE_HREF, CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import de from '@angular/common/locales/de';
import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EffectsModule } from '@ngrx/effects';
import moment from 'moment';
import 'moment/locale/de';
import { NgLetModule } from 'ng-let';
import { NgChartsModule } from 'ng2-charts';
import { NgrxFormsModule } from 'ngrx-forms';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MomentModule } from 'ngx-moment';
import { FilterOperatorComponent } from 'src/components/filteroperator/filteroperator.component';
import { ProcessPriorityArticleComponent } from 'src/components/priority/process-priority-article.component';
import { ProcessPriorityMessageComponent } from 'src/components/priority/process-priority-message.component';
import { ProcessPriorityOrderComponent } from 'src/components/priority/process-priority-order.component';
import { CustomErrorStateMatcherDirective } from 'src/directives/error-state-matcher.directive';
import { MatListOptionFixDirective } from 'src/directives/mat-list-option-fix.directive';
import { NgrxMatSelectViewAdapter } from 'src/directives/ngrx-mat-select-viewadapter.directive';
import { SafePipe } from 'src/pipes/safe.pipe';
import { StripPipe } from 'src/pipes/strip.pipe';
import { ArticlePriceDialogComponent } from '../components/dialog/dialog-article-price.component';
import { BundlePositionAddDialogComponent } from '../components/dialog/dialog-bundle-position-add.component';
import { BundlePriceDialogComponent } from '../components/dialog/dialog-bundle-price.component';
import { BundleComponentDialogComponent } from '../components/dialog/dialog-bundlecomponent.component';
import { ChangePaymentKindDialogComponent } from '../components/dialog/dialog-change-payment-kind.component';
import { ChangeShippingKindDialogComponent } from '../components/dialog/dialog-change-shipping-kind.component';
import { CommentDialogComponent } from '../components/dialog/dialog-comment.component';
import { ConfirmationDialogComponent } from '../components/dialog/dialog-confirmation.component';
import { DatabaseResetDialogComponent } from '../components/dialog/dialog-database-reset.component';
import { ErrorDialogComponent } from '../components/dialog/dialog-error.component';
import { OrdersPositionDialogComponent } from '../components/dialog/dialog-order-positions.component';
import { OrdersReturnPositionDialogComponent } from '../components/dialog/dialog-order-return-positions.component';
import { PageBlockDialogComponent } from '../components/dialog/dialog-pageblock.component';
import { PartnerPriceDialogComponent } from '../components/dialog/dialog-partner-price.component';
import { PaymentTypeDialogComponent } from '../components/dialog/dialog-payment-type.component';
import { PickupStationDialogComponent } from '../components/dialog/dialog-pickup-station.component';
import { PositionAddDialogComponent } from '../components/dialog/dialog-position-add.component';
import { PositionEditDialogComponent } from '../components/dialog/dialog-position-edit.component';
import { PostponeOrderDialogComponent } from '../components/dialog/dialog-postpone-order.component';
import { ReceiptReceiverDialogComponent } from '../components/dialog/dialog-receipt-receiver.component';
import { ReceiptTypeDialogComponent } from '../components/dialog/dialog-receipt-type.component';
import { ShippingTypeDialogComponent } from '../components/dialog/dialog-shipping-type.component';
import { UpdatedStoragePlacesDialogComponent } from '../components/dialog/dialog-updated-storage-places.component';
import { AttachmentAutocompleteMultipleComponent } from '../components/entityautocompletemultiple/entityautocompletemultiple-attachment.component';
import { CustomerAutocompleteMultipleComponent } from '../components/entityautocompletemultiple/entityautocompletemultiple-customer.component';
import { ImageAutocompleteMultipleComponent } from '../components/entityautocompletemultiple/entityautocompletemultiple-image.component';
import { MediaBrandAutocompleteMultipleComponent } from '../components/entityautocompletemultiple/entityautocompletemultiple-media-brand.component';
import { PermissionAutocompleteMultipleComponent } from '../components/entityautocompletemultiple/entityautocompletemultiple-permission.component';
import { ProcessNodeAutocompleteMultipleComponent } from '../components/entityautocompletemultiple/entityautocompletemultiple-process-node.component';
import { RegionAutocompleteMultipleComponent } from '../components/entityautocompletemultiple/entityautocompletemultiple-region.component';
import { UserAutocompleteMultipleComponent } from '../components/entityautocompletemultiple/entityautocompletemultiple-user.component';
import { AccessoryProductAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-accessory-product.component';
import { AccessoryStockAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-accessory-stock.component';
import { BundleAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-bundle.component';
import { CustomerAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-customer.component';
import { ImageAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-image.component';
import { MediaBrandAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-media-brand.component';
import { MediaProductAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-media-product.component';
import { MediaStockAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-media-stock.component';
import { OrderAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-order.component';
import { PageAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-page.component';
import { PartnerAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-partner.component';
import { PermissionAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-permission.component';
import { ProcessNodeAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-process-node.component';
import { ProcessRouteAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-process-route.component';
import { ProductAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-product.component';
import { PublicationCycleAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-publication-cycle.component';
import { RegionAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-region.component';
import { StoragePlaceAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-storage-place.component';
import { TextTemplateAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-text-template.component';
import { UserAutocompleteSingleComponent } from '../components/entityautocompletesingle/entityautocompletesingle-user.component';
import { ArticleHandoverDialogComponent } from '../components/entitydialog/entitydialog-article-handover.component';
import { ArticleOrderDialogComponent } from '../components/entitydialog/entitydialog-article-order.component';
import { ArticlePickDialogComponent } from '../components/entitydialog/entitydialog-article-pick.component';
import { ArticlePostcheckDialogComponent } from '../components/entitydialog/entitydialog-article-postcheck.component';
import { ArticleReproduceDialogComponent } from '../components/entitydialog/entitydialog-article-reproduce.component';
import { ArticleReturnDialogComponent } from '../components/entitydialog/entitydialog-article-return.component';
import { BirthdayBookAddDialogComponent } from '../components/entitydialog/entitydialog-birthday-book-add.component';
import { BirthdayBookReplaceDialogComponent } from '../components/entitydialog/entitydialog-birthday-book-replace.component';
import { BirthdayChronicleAddDialogComponent } from '../components/entitydialog/entitydialog-birthday-chronicle-add.component';
import { BirthdayChronicleReplaceDialogComponent } from '../components/entitydialog/entitydialog-birthday-chronicle-replace.component';
import { BookAddDialogComponent } from '../components/entitydialog/entitydialog-book-add.component';
import { BookReplaceDialogComponent } from '../components/entitydialog/entitydialog-book-replace.component';
import { CdAddDialogComponent } from '../components/entitydialog/entitydialog-cd-add.component';
import { CdReplaceDialogComponent } from '../components/entitydialog/entitydialog-cd-replace.component';
import { ChronicleAddDialogComponent } from '../components/entitydialog/entitydialog-chronicle-add.component';
import { ChronicleReplaceDialogComponent } from '../components/entitydialog/entitydialog-chronicle-replace.component';
import { CoinAddDialogComponent } from '../components/entitydialog/entitydialog-coin-add.component';
import { CoinReplaceDialogComponent } from '../components/entitydialog/entitydialog-coin-replace.component';
import { DvdAddDialogComponent } from '../components/entitydialog/entitydialog-dvd-add.component';
import { DvdReplaceDialogComponent } from '../components/entitydialog/entitydialog-dvd-replace.component';
import { FolderAddDialogComponent } from '../components/entitydialog/entitydialog-folder-add.component';
import { FolderReplaceDialogComponent } from '../components/entitydialog/entitydialog-folder-replace.component';
import { MagazineAddDialogComponent } from '../components/entitydialog/entitydialog-magazine-add.component';
import { MagazineReplaceDialogComponent } from '../components/entitydialog/entitydialog-magazine-replace.component';
import { NewspaperAddDialogComponent } from '../components/entitydialog/entitydialog-newspaper-add.component';
import { NewspaperReplaceDialogComponent } from '../components/entitydialog/entitydialog-newspaper-replace.component';
import { OrderDetailDialogComponent } from '../components/entitydialog/entitydialog-order-detail.component';
import { OrderSearchDialogComponent } from '../components/entitydialog/entitydialog-order-search.component';
import { PaymentPayDialogComponent } from '../components/entitydialog/entitydialog-payment-pay.component';
import { ShippingCommissionDialogComponent } from '../components/entitydialog/entitydialog-shipping-commission.component';
import { ShippingPrepareDialogComponent } from '../components/entitydialog/entitydialog-shipping-prepare.component';
import { StickAddDialogComponent } from '../components/entitydialog/entitydialog-stick-add.component';
import { StickReplaceDialogComponent } from '../components/entitydialog/entitydialog-stick-replace.component';
import { YearBookAddDialogComponent } from '../components/entitydialog/entitydialog-yearbook-add.component';
import { YearBookReplaceDialogComponent } from '../components/entitydialog/entitydialog-yearbook-replace.component';
import { AccessoryProductDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-accessory-product.component';
import { AccessoryStockDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-accessory-stock.component';
import { AccountingSummaryDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-accounting-summary.component';
import { ArticleDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-article.component';
import { AttachmentDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-attachment.component';
import { BarzahlenDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-barzahlen.component';
import { BirthdayBookDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-birthday-book.component';
import { BirthdayChronicleDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-birthday-chronicle.component';
import { BookDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-book.component';
import { BundleDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-bundle.component';
import { CancellationDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-cancellation.component';
import { CashOnDeliveryDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-cash-on-delivery.component';
import { CashDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-cash.component';
import { CdDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-cd.component';
import { CertificateDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-certificate.component';
import { ChronicleDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-chronicle.component';
import { CoinDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-coin.component';
import { CollectionPurchaseTransferDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-collection-purchase-transfer.component';
import { CollectionPurchaseDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-collection-purchase.component';
import { CorrectionDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-correction.component';
import { CustomerDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-customer.component';
import { DeliveryNoteOrderDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-delivery-note-order.component';
import { DeliveryNoteReturnDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-delivery-note-return.component';
import { DeliveryNoteDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-delivery-note.component';
import { DhlExpressDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-dhl-express.component';
import { DhlPremiumDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-dhl-premium.component';
import { DhlDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-dhl.component';
import { DirectDebitTransferDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-direct-debit-transfer.component';
import { DirectDebitDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-direct-debit.component';
import { DocumentDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-document.component';
import { DvdDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-dvd.component';
import { FolderDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-folder.component';
import { ImageDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-image.component';
import { InvoiceDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-invoice.component';
import { MagazineDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-magazine.component';
import { MailTemplateDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-mailtemplate.component';
import { MediaBrandDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-media-brand.component';
import { MediaProductDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-media-product.component';
import { MediaStockDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-media-stock.component';
import { MessageDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-message.component';
import { NewspaperDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-newspaper.component';
import { PageDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-page.component';
import { PartnerDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-partner.component';
import { PaypalDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-paypal.component';
import { PermissionDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-permission.component';
import { PickupDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-pickup.component';
import { PrepaymentDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-prepayment.component';
import { ProcessNodeDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-process-node.component';
import { ProcessRouteDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-process-route.component';
import { ProcessDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-process.component';
import { ProductDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-product.component';
import { PublicationCycleDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-publication-cycle.component';
import { PublisherDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-publisher.component';
import { PurchaseOnAccountDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-purchase-on-account.component';
import { ReceiptDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-receipt.component';
import { RegionDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-region.component';
import { ReturnDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-return.component';
import { StickDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-stick.component';
import { StockDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-stock.component';
import { StoragePlaceDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-storage-place.component';
import { TextTemplateDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-text-template.component';
import { UserDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-user.component';
import { YearBookDialogAddComponent } from '../components/entitydialogadd/entitydialogadd-yearbook.component';
import { AccessoryProductDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-accessory-product.component';
import { AccessoryStockDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-accessory-stock.component';
import { AccountingSummaryDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-accounting-summary.component';
import { ArticleDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-article.component';
import { AttachmentDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-attachment.component';
import { BarzahlenDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-barzahlen.component';
import { BirthdayBookDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-birthday-book.component';
import { BirthdayChronicleDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-birthday-chronicle.component';
import { BookDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-book.component';
import { BundleDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-bundle.component';
import { CancellationDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-cancellation.component';
import { CashOnDeliveryDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-cash-on-delivery.component';
import { CashDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-cash.component';
import { CdDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-cd.component';
import { CertificateDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-certificate.component';
import { ChronicleDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-chronicle.component';
import { CoinDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-coin.component';
import { CollectionPurchaseTransferDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-collection-purchase-transfer.component';
import { CollectionPurchaseDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-collection-purchase.component';
import { ConfigurationDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-configuration.component';
import { CorrectionDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-correction.component';
import { CounterDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-counter.component';
import { CustomerDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-customer.component';
import { DeliveryNoteOrderDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-delivery-note-order.component';
import { DeliveryNoteReturnDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-delivery-note-return.component';
import { DeliveryNoteDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-delivery-note.component';
import { DhlExpressDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-dhl-express.component';
import { DhlPremiumDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-dhl-premium.component';
import { DhlDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-dhl.component';
import { DirectDebitTransferDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-direct-debit-transfer.component';
import { DirectDebitDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-direct-debit.component';
import { DocumentDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-document.component';
import { DvdDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-dvd.component';
import { FolderDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-folder.component';
import { ImageDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-image.component';
import { InvoiceDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-invoice.component';
import { MagazineDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-magazine.component';
import { MailTemplateDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-mailtemplate.component';
import { MediaBrandDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-media-brand.component';
import { MediaProductDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-media-product.component';
import { MediaStockDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-media-stock.component';
import { MessageDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-message.component';
import { NewspaperDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-newspaper.component';
import { PageDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-page.component';
import { PartnerDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-partner.component';
import { PaymentDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-payment.component';
import { PaypalDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-paypal.component';
import { PermissionDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-permission.component';
import { PickupDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-pickup.component';
import { PrepaymentDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-prepayment.component';
import { ProcessNodeDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-process-node.component';
import { ProcessRouteDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-process-route.component';
import { ProcessDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-process.component';
import { ProductDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-product.component';
import { PublicationCycleDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-publication-cycle.component';
import { PublisherDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-publisher.component';
import { PurchaseOnAccountDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-purchase-on-account.component';
import { ReceiptDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-receipt.component';
import { RegionDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-region.component';
import { ReturnDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-return.component';
import { ShippingDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-shipping.component';
import { StickDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-stick.component';
import { StockDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-stock.component';
import { StoragePlaceDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-storage-place.component';
import { TextTemplateDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-text-template.component';
import { UserDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-user.component';
import { YearBookDialogChangeComponent } from '../components/entitydialogchange/entitydialogchange-yearbook.component';
import { AccessoryProductDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-accessory-product.component';
import { AccessoryStockDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-accessory-stock.component';
import { AccountingSummaryDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-accounting-summary.component';
import { AttachmentDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-attachment.component';
import { BarzahlenDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-barzahlen.component';
import { BirthdayBookDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-birthday-book.component';
import { BirthdayChronicleDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-birthday-chronicle.component';
import { BookDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-book.component';
import { BundleDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-bundle.component';
import { CancellationDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-cancellation.component';
import { CashOnDeliveryDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-cash-on-delivery.component';
import { CashDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-cash.component';
import { CdDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-cd.component';
import { CertificateDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-certificate.component';
import { ChronicleDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-chronicle.component';
import { CoinDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-coin.component';
import { CollectionPurchaseTransferDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-collection-purchase-transfer.component';
import { CollectionPurchaseDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-collection-purchase.component';
import { CorrectionDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-correction.component';
import { CustomerDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-customer.component';
import { DeliveryNoteOrderDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-delivery-note-order.component';
import { DeliveryNoteReturnDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-delivery-note-return.component';
import { DeliveryNoteDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-delivery-note.component';
import { DhlExpressDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-dhl-express.component';
import { DhlPremiumDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-dhl-premium.component';
import { DhlDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-dhl.component';
import { DirectDebitTransferDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-direct-debit-transfer.component';
import { DirectDebitDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-direct-debit.component';
import { DocumentDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-document.component';
import { DvdDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-dvd.component';
import { FolderDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-folder.component';
import { ImageDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-image.component';
import { InvoiceDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-invoice.component';
import { MagazineDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-magazine.component';
import { MailTemplateDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-mailtemplate.component';
import { MediaBrandDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-media-brand.component';
import { MediaProductDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-media-product.component';
import { MediaStockDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-media-stock.component';
import { MessageDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-message.component';
import { NewspaperDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-newspaper.component';
import { OrderDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-order.component';
import { PageDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-page.component';
import { PartnerDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-partner.component';
import { PaypalDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-paypal.component';
import { PermissionDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-permission.component';
import { PickupDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-pickup.component';
import { PrepaymentDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-prepayment.component';
import { ProcessNodeDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-process-node.component';
import { ProcessRouteDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-process-route.component';
import { ProcessDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-process.component';
import { ProductDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-product.component';
import { PublicationCycleDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-publication-cycle.component';
import { PublisherDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-publisher.component';
import { PurchaseOnAccountDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-purchase-on-account.component';
import { ReceiptDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-receipt.component';
import { RegionDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-region.component';
import { ReturnDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-return.component';
import { StickDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-stick.component';
import { StockDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-stock.component';
import { StoragePlaceDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-storage-place.component';
import { TextTemplateDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-text-template.component';
import { UserDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-user.component';
import { YearBookDialogDeleteComponent } from '../components/entitydialogdelete/entitydialogdelete-yearbook.component';
import { OrderDialogPreviewComponent } from '../components/entitydialogpreview/entitydialogpreview-order.component';
import { AccessoryProductPartnerPricesTableComponent } from '../components/entitydialogtable/entitydialogtable-accessory-product-partner-prices.component';
import { AccessoryStockHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-accessory-stockhistory.component';
import { AccountingSummaryHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-accounting-summaryhistory.component';
import { ArticlePricesTableComponent } from '../components/entitydialogtable/entitydialogtable-article-prices.component';
import { ArticleHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-articlehistory.component';
import { BirthdayBookHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-birthday-bookhistory.component';
import { BirthdayChronicleHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-birthday-chroniclehistory.component';
import { BookHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-bookhistory.component';
import { BundlePricesTableComponent } from '../components/entitydialogtable/entitydialogtable-bundle-prices.component';
import { BundleComponentsTableComponent } from '../components/entitydialogtable/entitydialogtable-bundlecomponents.component';
import { CancellationHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-cancellationhistory.component';
import { CdHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-cdhistory.component';
import { CertificateHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-certificatehistory.component';
import { ChronicleHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-chroniclehistory.component';
import { CoinHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-coinhistory.component';
import { CollectionPurchaseTransferHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-collection-purchase-transferhistory.component';
import { CollectionPurchaseHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-collection-purchasehistory.component';
import { CorrectionHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-correctionhistory.component';
import { DirectDebitTransferHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-direct-debit-transferhistory.component';
import { DirectDebitHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-direct-debithistory.component';
import { DvdHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-dvdhistory.component';
import { FolderHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-folderhistory.component';
import { InvoiceHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-invoicehistory.component';
import { MagazineHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-magazinehistory.component';
import { MediaProductPartnerPricesTableComponent } from '../components/entitydialogtable/entitydialogtable-media-product-partner-prices.component';
import { MediaStockHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-media-stockhistory.component';
import { NewspaperHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-newspaperhistory.component';
import { OrderHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-orderhistory.component';
import { PageBlocksTableComponent } from '../components/entitydialogtable/entitydialogtable-pageblocks.component';
import { PaymentTypesTableComponent } from '../components/entitydialogtable/entitydialogtable-payment-types.component';
import { PaymentHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-paymenthistory.component';
import { PaypalHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-paypalhistory.component';
import { PickupStationsTableComponent } from '../components/entitydialogtable/entitydialogtable-pickup-stations.component';
import { PrepaymentHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-prepaymenthistory.component';
import { PurchaseOnAccountHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-purchase-on-accounthistory.component';
import { ReceiptTypesTableComponent } from '../components/entitydialogtable/entitydialogtable-receipt-types.component';
import { ReturnHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-returnhistory.component';
import { ShippingTypesTableComponent } from '../components/entitydialogtable/entitydialogtable-shipping-types.component';
import { ShippingHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-shippinghistory.component';
import { StockHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-stockhistory.component';
import { YearBookHistoryTableComponent } from '../components/entitydialogtable/entitydialogtable-yearbookhistory.component';
import { TextTemplateDropdownSingleComponent } from '../components/entitydropdownsingle/entitydropdownsingle-text-template.component';
import { AuthorizationErrorComponent } from '../components/entitynotificationerror/authorizationerror.component';
import { AccessoryProductNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-accessory-product.component';
import { AccessoryStockNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-accessory-stock.component';
import { AccountingSummaryAdvancementNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-accounting-summary-advancement.component';
import { AccountingSummaryCustomerNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-accounting-summary-customer.component';
import { AccountingSummaryNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-accounting-summary.component';
import { ArticleNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-article.component';
import { AttachmentNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-attachment.component';
import { BarzahlenNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-barzahlen.component';
import { BirthdayBookNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-birthday-book.component';
import { BirthdayChronicleNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-birthday-chronicle.component';
import { BookNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-book.component';
import { BundleNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-bundle.component';
import { CancellationNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-cancellation.component';
import { CashOnDeliveryNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-cash-on-delivery.component';
import { CashNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-cash.component';
import { CdNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-cd.component';
import { CertificateNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-certificate.component';
import { ChronicleNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-chronicle.component';
import { CoinNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-coin.component';
import { CollectionPurchaseTransferNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-collection-purchase-transfer.component';
import { CollectionPurchaseNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-collection-purchase.component';
import { ConfigurationNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-configuration.component';
import { CorrectionNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-correction.component';
import { CounterNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-counter.component';
import { CustomerNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-customer.component';
import { DeliveryNoteOrderNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-delivery-note-order.component';
import { DeliveryNoteReturnNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-delivery-note-return.component';
import { DeliveryNoteNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-delivery-note.component';
import { DhlExpressNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-dhl-express.component';
import { DhlPremiumNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-dhl-premium.component';
import { DhlNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-dhl.component';
import { DirectDebitTransferNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-direct-debit-transfer.component';
import { DirectDebitNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-direct-debit.component';
import { DocumentNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-document.component';
import { DvdNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-dvd.component';
import { FolderNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-folder.component';
import { ImageNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-image.component';
import { InvoiceNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-invoice.component';
import { MagazineNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-magazine.component';
import { MailTemplateNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-mailtemplate.component';
import { MediaBrandNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-media-brand.component';
import { MediaProductNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-media-product.component';
import { MediaStockNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-media-stock.component';
import { MessageNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-message.component';
import { NewspaperNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-newspaper.component';
import { OrderNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-order.component';
import { PageNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-page.component';
import { PartnerNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-partner.component';
import { PaypalNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-paypal.component';
import { PermissionNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-permission.component';
import { PickupNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-pickup.component';
import { PrepaymentNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-prepayment.component';
import { ProcessNodeNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-process-node.component';
import { ProcessRouteNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-process-route.component';
import { ProcessNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-process.component';
import { ProductNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-product.component';
import { PublicationCycleNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-publication-cycle.component';
import { PublisherNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-publisher.component';
import { PurchaseOnAccountNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-purchase-on-account.component';
import { ReceiptNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-receipt.component';
import { RegionNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-region.component';
import { ReturnNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-return.component';
import { ShippingNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-shipping.component';
import { StickNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-stick.component';
import { StockNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-stock.component';
import { StoragePlaceNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-storage-place.component';
import { TextTemplateNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-text-template.component';
import { UploadedFileNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-uploaded-file.component';
import { UserNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-user.component';
import { YearBookNotificationErrorComponent } from '../components/entitynotificationerror/entitynotificationerror-yearbook.component';
import { AccessoryProductPageComponent } from '../components/entitypage/entitypage-accessory-product.component';
import { AccessoryStockPageComponent } from '../components/entitypage/entitypage-accessory-stock.component';
import { AccountingSummaryPageComponent } from '../components/entitypage/entitypage-accounting-summary.component';
import { ArticlePageComponent } from '../components/entitypage/entitypage-article.component';
import { AttachmentPageComponent } from '../components/entitypage/entitypage-attachment.component';
import { BirthdayBookPageComponent } from '../components/entitypage/entitypage-birthday-book.component';
import { BirthdayChroniclePageComponent } from '../components/entitypage/entitypage-birthday-chronicle.component';
import { BookPageComponent } from '../components/entitypage/entitypage-book.component';
import { BundlePageComponent } from '../components/entitypage/entitypage-bundle.component';
import { CdPageComponent } from '../components/entitypage/entitypage-cd.component';
import { CertificatePageComponent } from '../components/entitypage/entitypage-certificate.component';
import { ChroniclePageComponent } from '../components/entitypage/entitypage-chronicle.component';
import { CoinPageComponent } from '../components/entitypage/entitypage-coin.component';
import { CollectionPurchaseTransferPageComponent } from '../components/entitypage/entitypage-collection-purchase-transfer.component';
import { ConfigurationPageComponent } from '../components/entitypage/entitypage-configuration.component';
import { CounterPageComponent } from '../components/entitypage/entitypage-counter.component';
import { CustomerPageComponent } from '../components/entitypage/entitypage-customer.component';
import { DeliveryNotePageComponent } from '../components/entitypage/entitypage-delivery-note.component';
import { DirectDebitTransferPageComponent } from '../components/entitypage/entitypage-direct-debit-transfer.component';
import { DocumentPageComponent } from '../components/entitypage/entitypage-document.component';
import { DvdPageComponent } from '../components/entitypage/entitypage-dvd.component';
import { FolderPageComponent } from '../components/entitypage/entitypage-folder.component';
import { ImagePageComponent } from '../components/entitypage/entitypage-image.component';
import { MagazinePageComponent } from '../components/entitypage/entitypage-magazine.component';
import { MediaBrandPageComponent } from '../components/entitypage/entitypage-media-brand.component';
import { MediaProductPageComponent } from '../components/entitypage/entitypage-media-product.component';
import { MediaStockPageComponent } from '../components/entitypage/entitypage-media-stock.component';
import { NewspaperPageComponent } from '../components/entitypage/entitypage-newspaper.component';
import { OrderPageComponent } from '../components/entitypage/entitypage-order.component';
import { PagePageComponent } from '../components/entitypage/entitypage-page.component';
import { PartnerPageComponent } from '../components/entitypage/entitypage-partner.component';
import { PaymentPageComponent } from '../components/entitypage/entitypage-payment.component';
import { PaypalPageComponent } from '../components/entitypage/entitypage-paypal.component';
import { PublicationCyclePageComponent } from '../components/entitypage/entitypage-publication-cycle.component';
import { ReceiptPageComponent } from '../components/entitypage/entitypage-receipt.component';
import { RegionPageComponent } from '../components/entitypage/entitypage-region.component';
import { ShippingPageComponent } from '../components/entitypage/entitypage-shipping.component';
import { StickPageComponent } from '../components/entitypage/entitypage-stick.component';
import { StoragePlacePageComponent } from '../components/entitypage/entitypage-storage-place.component';
import { TextTemplatePageComponent } from '../components/entitypage/entitypage-text-template.component';
import { UploadedFilePageComponent } from '../components/entitypage/entitypage-uploaded-file.component';
import { UserPageComponent } from '../components/entitypage/entitypage-user.component';
import { YearBookPageComponent } from '../components/entitypage/entitypage-yearbook.component';
import { OrderPageAddComponent } from '../components/entitypageadd/entitypageadd-order.component';
import { OrderPageChangeComponent } from '../components/entitypagechange/entitypagechange-order.component';
import { FetchComponent } from '../components/fetch/fetch.component';
import { LoginpageComponent } from '../components/loginpage/loginpage.component';
import { PictureComponent } from '../components/picture/picture.component';
import { ProfilePictureComponent } from '../components/picture/profile-picture.component';
import { ProcessButtonsComponent } from '../components/process-buttons/process-buttons.component';
import { ProcessStateComponent } from '../components/process-state/process-state.component';
import { StartpageComponent } from '../components/startpage/startpage.component';
import { BooleanPipe } from '../pipes/boolean.pipe';
import { CastCertificatePipe } from '../pipes/cast.pipe';
import { JoinPipe } from '../pipes/join.pipe';
import { StringPipe } from '../pipes/string.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { BlobErrorHttpInterceptor } from '../services/utility/blob-error-interceptor.service';
import { GlobalErrorHandler } from '../services/utility/error-handler.service';
import { AccessoryProductEffects } from '../state/accessory-product/accessory-product.effects';
import { AccountingSummaryAdvancementEffects } from '../state/accounting-summary.advancement/accounting-summary.advancement.effects';
import { AccountingSummaryCustomerEffects } from '../state/accounting-summary.customer/accounting-summary.customer.effects';
import { AggregationEffects } from '../state/aggregation/aggregation.effects';
import { ArticleBirthdayBookEffects } from '../state/article.birthday-book/article.birthday-book.effects';
import { ArticleBirthdayChronicleEffects } from '../state/article.birthday-chronicle/article.birthday-chronicle.effects';
import { ArticleBookEffects } from '../state/article.book/article.book.effects';
import { ArticleCdEffects } from '../state/article.cd/article.cd.effects';
import { ArticleCertificateEffects } from '../state/article.certificate/article.certificate.effects';
import { ArticleChronicleEffects } from '../state/article.chronicle/article.chronicle.effects';
import { ArticleCoinEffects } from '../state/article.coin/article.coin.effects';
import { ArticleDvdEffects } from '../state/article.dvd/article.dvd.effects';
import { ArticleFolderEffects } from '../state/article.folder/article.folder.effects';
import { ArticleMagazineEffects } from '../state/article.magazine/article.magazine.effects';
import { ArticleNewspaperEffects } from '../state/article.newspaper/article.newspaper.effects';
import { ArticleStickEffects } from '../state/article.stick/article.stick.effects';
import { ArticleYearBookEffects } from '../state/article.yearbook/article.yearbook.effects';
import { ArticleEffects } from '../state/article/article.effects';
import { AttachmentEffects } from '../state/attachment/attachment.effects';
import { BundleEffects } from '../state/bundle/bundle.effects';
import { CartEffects } from '../state/cart/cart.effects';
import { CollectionPurchaseTransferEffects } from '../state/collection-purchase-transfer/collection-purchase-transfer.effects';
import { ConfigurationEffects } from '../state/configuration/configuration.effects';
import { CounterEffects } from '../state/counter/counter.effects';
import { CustomerEffects } from '../state/customer/customer.effects';
import { DeliveryNoteOrderEffects } from '../state/delivery-note.order/delivery-note.order.effects';
import { DeliveryNoteReturnEffects } from '../state/delivery-note.return/delivery-note.return.effects';
import { DeliveryNoteEffects } from '../state/delivery-note/delivery-note.effects';
import { DeviceEffects } from '../state/device/device.effects';
import { DirectDebitTransferEffects } from '../state/direct-debit-transfer/direct-debit-transfer.effects';
import { DocumentEffects } from '../state/document/document.effects';
import { ImageEffects } from '../state/image/image.effects';
import { MailTemplateEffects } from '../state/mailtemplate/mailtemplate.effects';
import { MediaBrandEffects } from '../state/media-brand/media-brand.effects';
import { MediaProductEffects } from '../state/media-product/media-product.effects';
import { MessageEffects } from '../state/message/message.effects';
import { OrderEffects } from '../state/order/order.effects';
import { PageEffects } from '../state/page/page.effects';
import { PartnerEffects } from '../state/partner/partner.effects';
import { BarzahlenEffects } from '../state/payment.barzahlen/payment.barzahlen.effects';
import { CashOnDeliveryEffects } from '../state/payment.cash-on-delivery/payment.cash-on-delivery.effects';
import { CashEffects } from '../state/payment.cash/payment.cash.effects';
import { CollectionPurchaseEffects } from '../state/payment.collection-purchase/payment.collection-purchase.effects';
import { DirectDebitEffects } from '../state/payment.direct-debit/payment.direct-debit.effects';
import { PaypalEffects } from '../state/payment.paypal/payment.paypal.effects';
import { PrepaymentEffects } from '../state/payment.prepayment/payment.prepayment.effects';
import { PurchaseOnAccountEffects } from '../state/payment.purchase-on-account/payment.purchase-on-account.effects';
import { PaymentEffects } from '../state/payment/payment.effects';
import { PermissionEffects } from '../state/permission/permission.effects';
import { PredictionEffects } from '../state/prediction/prediction.effects';
import { ProcessNodeEffects } from '../state/process-node/process-node.effects';
import { ProcessRouteEffects } from '../state/process-route/process-route.effects';
import { ProcessEffects } from '../state/process/process.effects';
import { ProductEffects } from '../state/product/product.effects';
import { PublicationCycleEffects } from '../state/publication-cycle/publication-cycle.effects';
import { PublisherEffects } from '../state/publisher/publisher.effects';
import { CancellationEffects } from '../state/receipt.cancellation/receipt.cancellation.effects';
import { CorrectionEffects } from '../state/receipt.correction/receipt.correction.effects';
import { InvoiceEffects } from '../state/receipt.invoice/receipt.invoice.effects';
import { ReceiptEffects } from '../state/receipt/receipt.effects';
import { RegionEffects } from '../state/region/region.effects';
import { SessionEffects } from '../state/session/session.effects';
import { DhlExpressEffects } from '../state/shipping.dhl-express/shipping.dhl-express.effects';
import { DhlPremiumEffects } from '../state/shipping.dhl-premium/shipping.dhl-premium.effects';
import { DhlEffects } from '../state/shipping.dhl/shipping.dhl.effects';
import { PickupEffects } from '../state/shipping.pickup/shipping.pickup.effects';
import { ReturnEffects } from '../state/shipping.return/shipping.return.effects';
import { ShippingEffects } from '../state/shipping/shipping.effects';
import { StatisticsEffects } from '../state/statistics/statistics.effects';
import { AccessoryStockEffects } from '../state/stock.accessory/stock.accessory.effects';
import { MediaStockEffects } from '../state/stock.media/stock.media.effects';
import { StockEffects } from '../state/stock/stock.effects';
import { StoragePlaceEffects } from '../state/storage-place/storage-place.effects';
import { TextTemplateEffects } from '../state/text-template/text-template.effects';
import { UploadedFileEffects } from '../state/uploadedfile/uploadedfile.effects';
import { UserEffects } from '../state/user/user.effects';
import { getGermanPaginatorIntl } from '../types/paginator.intl';
import { CashOnDeliveryHistoryTableComponent } from './../components/entitydialogtable/entitydialogtable-cash-on-deliveryhistory.component';
import { CashHistoryTableComponent } from './../components/entitydialogtable/entitydialogtable-cashhistory.component';
import { DhlExpressHistoryTableComponent } from './../components/entitydialogtable/entitydialogtable-dhl-expresshistory.component';
import { DhlPremiumHistoryTableComponent } from './../components/entitydialogtable/entitydialogtable-dhl-premiumhistory.component';
import { DhlHistoryTableComponent } from './../components/entitydialogtable/entitydialogtable-dhlhistory.component';
import { PickupHistoryTableComponent } from './../components/entitydialogtable/entitydialogtable-pickuphistory.component';
import { AccountingSummaryEffects } from './../state/accounting-summary/accounting-summary.effects';

@NgModule({
	declarations: [
		CustomErrorStateMatcherDirective,
		FilterOperatorComponent,
		MatListOptionFixDirective,
		NgrxMatSelectViewAdapter,
		SafePipe,
		StripPipe,
		StringPipe,
		TruncatePipe,
		BooleanPipe,
		JoinPipe,
		CastCertificatePipe,
		PictureComponent,
		ProfilePictureComponent,
		FetchComponent,
		ProcessButtonsComponent,
		ProcessStateComponent,
		StartpageComponent,
		AuthorizationErrorComponent,
		ProcessPriorityOrderComponent,
		ProcessPriorityArticleComponent,
		ProcessPriorityMessageComponent,

		UserPageComponent,
		UserDialogAddComponent,
		UserDialogChangeComponent,
		UserDialogDeleteComponent,
		UserNotificationErrorComponent,
		UserAutocompleteSingleComponent,
		UserAutocompleteMultipleComponent,

		PartnerPageComponent,
		PartnerDialogAddComponent,
		PartnerDialogChangeComponent,
		PartnerDialogDeleteComponent,
		PartnerNotificationErrorComponent,
		PartnerAutocompleteSingleComponent,
		ArticlePricesTableComponent,
		ArticlePriceDialogComponent,
		BundlePricesTableComponent,
		BundlePriceDialogComponent,
		AccessoryProductPartnerPricesTableComponent,
		MediaProductPartnerPricesTableComponent,
		PartnerPriceDialogComponent,

		PagePageComponent,
		PageDialogAddComponent,
		PageDialogChangeComponent,
		PageDialogDeleteComponent,
		PageNotificationErrorComponent,
		PageAutocompleteSingleComponent,
		PageBlocksTableComponent,
		PageBlockDialogComponent,

		BundlePageComponent,
		BundleDialogAddComponent,
		BundleDialogChangeComponent,
		BundleDialogDeleteComponent,
		BundleComponentsTableComponent,
		BundleNotificationErrorComponent,
		BundleComponentDialogComponent,

		CustomerPageComponent,
		CustomerDialogAddComponent,
		CustomerDialogChangeComponent,
		CustomerDialogDeleteComponent,
		CustomerNotificationErrorComponent,
		CustomerAutocompleteSingleComponent,
		CustomerAutocompleteMultipleComponent,

		ProductDialogAddComponent,
		ProductDialogChangeComponent,
		ProductDialogDeleteComponent,
		ProductNotificationErrorComponent,

		NewspaperPageComponent,
		NewspaperDialogAddComponent,
		NewspaperDialogChangeComponent,
		NewspaperDialogDeleteComponent,
		NewspaperNotificationErrorComponent,
		NewspaperHistoryTableComponent,
		NewspaperReplaceDialogComponent,
		NewspaperAddDialogComponent,

		MagazinePageComponent,
		MagazineDialogAddComponent,
		MagazineDialogChangeComponent,
		MagazineDialogDeleteComponent,
		MagazineNotificationErrorComponent,
		MagazineHistoryTableComponent,
		MagazineReplaceDialogComponent,
		MagazineAddDialogComponent,

		CdPageComponent,
		CdDialogAddComponent,
		CdDialogChangeComponent,
		CdDialogDeleteComponent,
		CdNotificationErrorComponent,
		CdHistoryTableComponent,
		CdReplaceDialogComponent,
		CdAddDialogComponent,

		DvdPageComponent,
		DvdDialogAddComponent,
		DvdDialogChangeComponent,
		DvdDialogDeleteComponent,
		DvdNotificationErrorComponent,
		DvdHistoryTableComponent,
		DvdReplaceDialogComponent,
		DvdAddDialogComponent,

		CoinPageComponent,
		CoinDialogAddComponent,
		CoinDialogChangeComponent,
		CoinDialogDeleteComponent,
		CoinNotificationErrorComponent,
		CoinHistoryTableComponent,
		CoinReplaceDialogComponent,
		CoinAddDialogComponent,

		CertificatePageComponent,
		CertificateDialogAddComponent,
		CertificateDialogChangeComponent,
		CertificateDialogDeleteComponent,
		CertificateNotificationErrorComponent,
		CertificateHistoryTableComponent,

		StickPageComponent,
		StickDialogAddComponent,
		StickDialogChangeComponent,
		StickDialogDeleteComponent,
		StickNotificationErrorComponent,
		StickReplaceDialogComponent,
		StickAddDialogComponent,

		BookPageComponent,
		BookDialogAddComponent,
		BookDialogChangeComponent,
		BookDialogDeleteComponent,
		BookNotificationErrorComponent,
		BookHistoryTableComponent,
		BookReplaceDialogComponent,
		BookAddDialogComponent,

		ChroniclePageComponent,
		ChronicleDialogAddComponent,
		ChronicleDialogChangeComponent,
		ChronicleDialogDeleteComponent,
		ChronicleNotificationErrorComponent,
		ChronicleHistoryTableComponent,
		ChronicleReplaceDialogComponent,
		ChronicleAddDialogComponent,

		YearBookPageComponent,
		YearBookDialogAddComponent,
		YearBookDialogChangeComponent,
		YearBookDialogDeleteComponent,
		YearBookNotificationErrorComponent,
		YearBookHistoryTableComponent,
		YearBookReplaceDialogComponent,
		YearBookAddDialogComponent,

		BirthdayBookPageComponent,
		BirthdayBookDialogAddComponent,
		BirthdayBookDialogChangeComponent,
		BirthdayBookDialogDeleteComponent,
		BirthdayBookNotificationErrorComponent,
		BirthdayBookHistoryTableComponent,
		BirthdayBookReplaceDialogComponent,
		BirthdayBookAddDialogComponent,

		BirthdayChroniclePageComponent,
		BirthdayChronicleDialogAddComponent,
		BirthdayChronicleDialogChangeComponent,
		BirthdayChronicleDialogDeleteComponent,
		BirthdayChronicleNotificationErrorComponent,
		BirthdayChronicleHistoryTableComponent,
		BirthdayChronicleReplaceDialogComponent,
		BirthdayChronicleAddDialogComponent,

		FolderPageComponent,
		FolderDialogAddComponent,
		FolderDialogChangeComponent,
		FolderDialogDeleteComponent,
		FolderNotificationErrorComponent,
		FolderHistoryTableComponent,
		FolderReplaceDialogComponent,
		FolderAddDialogComponent,

		ArticleDialogAddComponent,
		ArticleDialogChangeComponent,
		ArticleNotificationErrorComponent,
		ArticleHistoryTableComponent,
		ArticlePickDialogComponent,
		ArticleReproduceDialogComponent,
		ArticleHandoverDialogComponent,
		ArticleOrderDialogComponent,
		ArticlePostcheckDialogComponent,
		ArticleReturnDialogComponent,
		ArticlePageComponent,

		ShippingPrepareDialogComponent,
		ShippingCommissionDialogComponent,

		PaymentPayDialogComponent,
		OrderSearchDialogComponent,
		OrderDetailDialogComponent,

		MailTemplateDialogAddComponent,
		MailTemplateDialogChangeComponent,
		MailTemplateDialogDeleteComponent,
		MailTemplateNotificationErrorComponent,

		PermissionDialogAddComponent,
		PermissionDialogChangeComponent,
		PermissionDialogDeleteComponent,
		PermissionNotificationErrorComponent,
		PermissionAutocompleteSingleComponent,
		PermissionAutocompleteMultipleComponent,

		DeliveryNotePageComponent,
		DeliveryNoteDialogAddComponent,
		DeliveryNoteDialogChangeComponent,
		DeliveryNoteDialogDeleteComponent,
		DeliveryNoteNotificationErrorComponent,

		DeliveryNoteOrderDialogAddComponent,
		DeliveryNoteOrderDialogChangeComponent,
		DeliveryNoteOrderDialogDeleteComponent,
		DeliveryNoteOrderNotificationErrorComponent,

		DeliveryNoteReturnDialogAddComponent,
		DeliveryNoteReturnDialogChangeComponent,
		DeliveryNoteReturnDialogDeleteComponent,
		DeliveryNoteReturnNotificationErrorComponent,

		ReceiptPageComponent,
		ReceiptDialogAddComponent,
		ReceiptDialogChangeComponent,
		ReceiptDialogDeleteComponent,
		ReceiptNotificationErrorComponent,

		InvoiceDialogAddComponent,
		InvoiceDialogChangeComponent,
		InvoiceDialogDeleteComponent,
		InvoiceNotificationErrorComponent,
		InvoiceHistoryTableComponent,

		CancellationDialogAddComponent,
		CancellationDialogChangeComponent,
		CancellationDialogDeleteComponent,
		CancellationNotificationErrorComponent,
		CancellationHistoryTableComponent,

		CorrectionDialogAddComponent,
		CorrectionDialogChangeComponent,
		CorrectionDialogDeleteComponent,
		CorrectionNotificationErrorComponent,
		CorrectionHistoryTableComponent,

		OrderPageComponent,
		OrderPageChangeComponent,
		OrderPageAddComponent,
		OrderDialogDeleteComponent,
		OrderNotificationErrorComponent,
		OrderHistoryTableComponent,
		OrderDialogPreviewComponent,
		OrdersPositionDialogComponent,
		OrdersReturnPositionDialogComponent,
		OrderAutocompleteSingleComponent,
		ReceiptReceiverDialogComponent,

		ShippingPageComponent,
		ShippingDialogChangeComponent,
		ShippingNotificationErrorComponent,
		ChangeShippingKindDialogComponent,

		UploadedFilePageComponent,

		PaymentPageComponent,
		PaypalPageComponent,
		PaymentDialogChangeComponent,
		ChangePaymentKindDialogComponent,

		RegionPageComponent,
		RegionDialogAddComponent,
		RegionDialogChangeComponent,
		RegionDialogDeleteComponent,
		RegionNotificationErrorComponent,
		RegionAutocompleteSingleComponent,
		RegionAutocompleteMultipleComponent,

		ImagePageComponent,
		ImageDialogAddComponent,
		ImageDialogChangeComponent,
		ImageDialogDeleteComponent,
		ImageNotificationErrorComponent,
		ImageAutocompleteSingleComponent,
		ImageAutocompleteMultipleComponent,

		DocumentPageComponent,
		DocumentDialogAddComponent,
		DocumentDialogChangeComponent,
		DocumentDialogDeleteComponent,
		DocumentNotificationErrorComponent,

		AttachmentPageComponent,
		AttachmentDialogAddComponent,
		AttachmentDialogChangeComponent,
		AttachmentDialogDeleteComponent,
		AttachmentNotificationErrorComponent,
		AttachmentAutocompleteMultipleComponent,

		StockDialogAddComponent,
		StockDialogChangeComponent,
		StockDialogDeleteComponent,
		StockNotificationErrorComponent,
		StockHistoryTableComponent,

		AccountingSummaryPageComponent,
		AccountingSummaryDialogAddComponent,
		AccountingSummaryDialogChangeComponent,
		AccountingSummaryDialogDeleteComponent,
		AccountingSummaryNotificationErrorComponent,
		AccountingSummaryAdvancementNotificationErrorComponent,
		AccountingSummaryCustomerNotificationErrorComponent,
		AccountingSummaryHistoryTableComponent,

		DirectDebitTransferPageComponent,
		DirectDebitTransferDialogAddComponent,
		DirectDebitTransferDialogChangeComponent,
		DirectDebitTransferDialogDeleteComponent,
		DirectDebitTransferNotificationErrorComponent,
		DirectDebitTransferHistoryTableComponent,

		CollectionPurchaseTransferPageComponent,
		CollectionPurchaseTransferDialogAddComponent,
		CollectionPurchaseTransferDialogChangeComponent,
		CollectionPurchaseTransferDialogDeleteComponent,
		CollectionPurchaseTransferNotificationErrorComponent,
		CollectionPurchaseTransferHistoryTableComponent,

		MediaStockPageComponent,
		MediaStockDialogAddComponent,
		MediaStockDialogChangeComponent,
		MediaStockDialogDeleteComponent,
		MediaStockNotificationErrorComponent,
		MediaStockHistoryTableComponent,
		MediaStockAutocompleteSingleComponent,

		AccessoryStockPageComponent,
		AccessoryStockDialogAddComponent,
		AccessoryStockDialogChangeComponent,
		AccessoryStockDialogDeleteComponent,
		AccessoryStockNotificationErrorComponent,
		AccessoryStockHistoryTableComponent,
		AccessoryStockAutocompleteSingleComponent,

		MediaBrandPageComponent,
		MediaBrandDialogAddComponent,
		MediaBrandDialogChangeComponent,
		MediaBrandDialogDeleteComponent,
		MediaBrandNotificationErrorComponent,
		MediaBrandAutocompleteSingleComponent,
		MediaBrandAutocompleteMultipleComponent,

		MediaProductPageComponent,
		MediaProductDialogAddComponent,
		MediaProductDialogChangeComponent,
		MediaProductDialogDeleteComponent,
		MediaProductNotificationErrorComponent,
		MediaProductAutocompleteSingleComponent,

		AccessoryProductPageComponent,
		AccessoryProductDialogAddComponent,
		AccessoryProductDialogChangeComponent,
		AccessoryProductDialogDeleteComponent,
		AccessoryProductNotificationErrorComponent,
		AccessoryProductAutocompleteSingleComponent,

		ProductAutocompleteSingleComponent,

		PublicationCyclePageComponent,
		PublicationCycleDialogAddComponent,
		PublicationCycleDialogChangeComponent,
		PublicationCycleDialogDeleteComponent,
		PublicationCycleNotificationErrorComponent,
		PublicationCycleAutocompleteSingleComponent,

		TextTemplatePageComponent,
		TextTemplateDialogAddComponent,
		TextTemplateDialogChangeComponent,
		TextTemplateDialogDeleteComponent,
		TextTemplateNotificationErrorComponent,
		TextTemplateAutocompleteSingleComponent,
		TextTemplateDropdownSingleComponent,

		PublisherDialogAddComponent,
		PublisherDialogChangeComponent,
		PublisherDialogDeleteComponent,
		PublisherNotificationErrorComponent,

		StoragePlacePageComponent,
		StoragePlaceDialogAddComponent,
		StoragePlaceDialogChangeComponent,
		StoragePlaceDialogDeleteComponent,
		StoragePlaceNotificationErrorComponent,
		StoragePlaceAutocompleteSingleComponent,

		ProcessNodeDialogAddComponent,
		ProcessNodeDialogChangeComponent,
		ProcessNodeDialogDeleteComponent,
		ProcessNodeNotificationErrorComponent,
		ProcessNodeAutocompleteSingleComponent,
		ProcessNodeAutocompleteMultipleComponent,

		ProcessRouteDialogAddComponent,
		ProcessRouteDialogChangeComponent,
		ProcessRouteDialogDeleteComponent,
		ProcessRouteNotificationErrorComponent,
		ProcessRouteAutocompleteSingleComponent,

		ProcessDialogAddComponent,
		ProcessDialogChangeComponent,
		ProcessDialogDeleteComponent,
		ProcessNotificationErrorComponent,

		ConfigurationPageComponent,
		ConfigurationDialogChangeComponent,
		ConfigurationNotificationErrorComponent,

		CounterPageComponent,
		CounterDialogChangeComponent,
		CounterNotificationErrorComponent,
		ShippingTypesTableComponent,
		ShippingTypeDialogComponent,
		PaymentTypesTableComponent,
		PaymentTypeDialogComponent,
		ReceiptTypesTableComponent,
		ReceiptTypeDialogComponent,
		PickupStationDialogComponent,
		PickupStationsTableComponent,

		ShippingHistoryTableComponent,
		PaymentHistoryTableComponent,

		BarzahlenDialogAddComponent,
		BarzahlenDialogChangeComponent,
		BarzahlenDialogDeleteComponent,
		BarzahlenNotificationErrorComponent,

		CashDialogAddComponent,
		CashDialogChangeComponent,
		CashDialogDeleteComponent,
		CashNotificationErrorComponent,
		CashHistoryTableComponent,

		CashOnDeliveryDialogAddComponent,
		CashOnDeliveryDialogChangeComponent,
		CashOnDeliveryDialogDeleteComponent,
		CashOnDeliveryNotificationErrorComponent,
		CashOnDeliveryHistoryTableComponent,

		DirectDebitDialogAddComponent,
		DirectDebitDialogChangeComponent,
		DirectDebitDialogDeleteComponent,
		DirectDebitNotificationErrorComponent,
		DirectDebitHistoryTableComponent,

		PrepaymentDialogAddComponent,
		PrepaymentDialogChangeComponent,
		PrepaymentDialogDeleteComponent,
		PrepaymentNotificationErrorComponent,
		PrepaymentHistoryTableComponent,

		PurchaseOnAccountDialogAddComponent,
		PurchaseOnAccountDialogChangeComponent,
		PurchaseOnAccountDialogDeleteComponent,
		PurchaseOnAccountNotificationErrorComponent,
		PurchaseOnAccountHistoryTableComponent,

		CollectionPurchaseDialogAddComponent,
		CollectionPurchaseDialogChangeComponent,
		CollectionPurchaseDialogDeleteComponent,
		CollectionPurchaseNotificationErrorComponent,
		CollectionPurchaseHistoryTableComponent,

		PaypalDialogAddComponent,
		PaypalDialogChangeComponent,
		PaypalDialogDeleteComponent,
		PaypalNotificationErrorComponent,
		PaypalHistoryTableComponent,

		DhlDialogAddComponent,
		DhlDialogChangeComponent,
		DhlDialogDeleteComponent,
		DhlNotificationErrorComponent,
		DhlHistoryTableComponent,

		ReturnDialogAddComponent,
		ReturnDialogChangeComponent,
		ReturnDialogDeleteComponent,
		ReturnNotificationErrorComponent,
		ReturnHistoryTableComponent,

		MessageDialogAddComponent,
		MessageDialogChangeComponent,
		MessageDialogDeleteComponent,
		MessageNotificationErrorComponent,

		DhlPremiumDialogAddComponent,
		DhlPremiumDialogChangeComponent,
		DhlPremiumDialogDeleteComponent,
		DhlPremiumNotificationErrorComponent,
		DhlPremiumHistoryTableComponent,

		DhlExpressDialogAddComponent,
		DhlExpressDialogChangeComponent,
		DhlExpressDialogDeleteComponent,
		DhlExpressNotificationErrorComponent,
		DhlExpressHistoryTableComponent,

		PickupDialogAddComponent,
		PickupDialogChangeComponent,
		PickupDialogDeleteComponent,
		PickupNotificationErrorComponent,
		PickupHistoryTableComponent,

		PickupDialogAddComponent,
		PickupDialogChangeComponent,
		PickupDialogDeleteComponent,
		PickupNotificationErrorComponent,

		CorrectionDialogAddComponent,
		CorrectionDialogChangeComponent,
		CorrectionDialogDeleteComponent,
		CorrectionNotificationErrorComponent,

		CancellationDialogAddComponent,
		CancellationDialogChangeComponent,
		CancellationDialogDeleteComponent,
		CancellationNotificationErrorComponent,

		InvoiceDialogAddComponent,
		InvoiceDialogChangeComponent,
		InvoiceDialogDeleteComponent,
		InvoiceNotificationErrorComponent,

		PostponeOrderDialogComponent,
		ConfirmationDialogComponent,
		ErrorDialogComponent,
		CommentDialogComponent,
		PositionEditDialogComponent,
		PositionAddDialogComponent,
		BundlePositionAddDialogComponent,
		DatabaseResetDialogComponent,
		BundleAutocompleteSingleComponent,

		UpdatedStoragePlacesDialogComponent,

		UploadedFileNotificationErrorComponent,
		LoginpageComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		MomentModule,
		NgChartsModule,
		NgrxFormsModule,
		ReactiveFormsModule,
		MatDialogModule,
		ScrollingModule,
		FormsModule,
		MatProgressSpinnerModule,
		MatButtonModule,
		MatFormFieldModule,
		MatSelectModule,
		MatListModule,
		MatSidenavModule,
		MatMenuModule,
		MatIconModule,
		MatButtonToggleModule,
		MatBadgeModule,
		MatCardModule,
		MatExpansionModule,
		MatSnackBarModule,
		MatToolbarModule,
		MatTooltipModule,
		MatProgressBarModule,
		CKEditorModule,
		FlexLayoutModule,
		CdkTableModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatDatepickerModule,
		MatTabsModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		DragDropModule,
		MatInputModule,
		NgrxFormsModule,
		MaterialFileInputModule,
		MatAutocompleteModule,
		MatDividerModule,
		MatChipsModule,
		MatInputModule,
		NgLetModule,
		EffectsModule.forFeature([
			StatisticsEffects,
			AggregationEffects,
			PredictionEffects,
			SessionEffects,
			DeviceEffects,
			UserEffects,
			ConfigurationEffects,
			CounterEffects,
			PublisherEffects,
			MediaBrandEffects,
			MediaProductEffects,
			PublicationCycleEffects,
			TextTemplateEffects,
			CustomerEffects,
			OrderEffects,
			MessageEffects,
			ProductEffects,
			ArticleEffects,
			PermissionEffects,
			ReceiptEffects,
			RegionEffects,
			ImageEffects,
			DocumentEffects,
			AttachmentEffects,
			ProcessNodeEffects,
			ProcessRouteEffects,
			ArticleNewspaperEffects,
			ArticleMagazineEffects,
			ArticleCdEffects,
			ArticleDvdEffects,
			ArticleFolderEffects,
			ArticleBookEffects,
			AccessoryProductEffects,
			StoragePlaceEffects,
			MailTemplateEffects,
			StockEffects,
			UploadedFileEffects,
			ShippingEffects,
			DhlEffects,
			ReturnEffects,
			DhlExpressEffects,
			DhlPremiumEffects,
			PickupEffects,
			PaymentEffects,
			BarzahlenEffects,
			CashEffects,
			CashOnDeliveryEffects,
			DeliveryNoteEffects,
			DeliveryNoteOrderEffects,
			DeliveryNoteReturnEffects,
			DirectDebitEffects,
			PaypalEffects,
			PrepaymentEffects,
			PurchaseOnAccountEffects,
			CollectionPurchaseEffects,
			ArticleCoinEffects,
			ArticleCertificateEffects,
			MediaStockEffects,
			AccessoryStockEffects,
			ArticleStickEffects,
			ArticleChronicleEffects,
			ArticleYearBookEffects,
			ArticleBirthdayBookEffects,
			ArticleBirthdayChronicleEffects,
			ProcessEffects,
			InvoiceEffects,
			CorrectionEffects,
			CancellationEffects,
			BundleEffects,
			PartnerEffects,
			PageEffects,
			CartEffects,
			AccountingSummaryEffects,
			AccountingSummaryAdvancementEffects,
			AccountingSummaryCustomerEffects,
			DirectDebitTransferEffects,
			CollectionPurchaseTransferEffects,
		]),
	],
	exports: [
		CustomErrorStateMatcherDirective,
		MatListOptionFixDirective,
		NgrxMatSelectViewAdapter,
		StartpageComponent,
		AuthorizationErrorComponent,

		SafePipe,
		StripPipe,
		StringPipe,
		TruncatePipe,
		BooleanPipe,
		JoinPipe,
		CastCertificatePipe,

		PictureComponent,
		ProfilePictureComponent,
		FetchComponent,
		ProcessButtonsComponent,
		ProcessStateComponent,
		FilterOperatorComponent,
		ProcessPriorityOrderComponent,
		ProcessPriorityArticleComponent,
		ProcessPriorityMessageComponent,

		UserPageComponent,
		UserDialogAddComponent,
		UserDialogChangeComponent,
		UserDialogDeleteComponent,
		UserNotificationErrorComponent,
		UserAutocompleteSingleComponent,
		UserAutocompleteMultipleComponent,

		CustomerPageComponent,
		CustomerDialogAddComponent,
		CustomerDialogChangeComponent,
		CustomerDialogDeleteComponent,
		CustomerNotificationErrorComponent,
		CustomerAutocompleteSingleComponent,
		CustomerAutocompleteMultipleComponent,

		ProductDialogAddComponent,
		ProductDialogChangeComponent,
		ProductDialogDeleteComponent,
		ProductNotificationErrorComponent,

		NewspaperPageComponent,
		NewspaperDialogAddComponent,
		NewspaperDialogChangeComponent,
		NewspaperDialogDeleteComponent,
		NewspaperNotificationErrorComponent,
		NewspaperHistoryTableComponent,
		NewspaperReplaceDialogComponent,
		NewspaperAddDialogComponent,

		MagazinePageComponent,
		MagazineDialogAddComponent,
		MagazineDialogChangeComponent,
		MagazineDialogDeleteComponent,
		MagazineNotificationErrorComponent,
		MagazineHistoryTableComponent,
		MagazineReplaceDialogComponent,
		MagazineAddDialogComponent,

		CdPageComponent,
		CdDialogAddComponent,
		CdDialogChangeComponent,
		CdDialogDeleteComponent,
		CdNotificationErrorComponent,
		CdHistoryTableComponent,
		CdReplaceDialogComponent,
		CdAddDialogComponent,

		DvdPageComponent,
		DvdDialogAddComponent,
		DvdDialogChangeComponent,
		DvdDialogDeleteComponent,
		DvdNotificationErrorComponent,
		DvdHistoryTableComponent,
		DvdReplaceDialogComponent,
		DvdAddDialogComponent,

		CoinPageComponent,
		CoinDialogAddComponent,
		CoinDialogChangeComponent,
		CoinDialogDeleteComponent,
		CoinNotificationErrorComponent,
		CoinHistoryTableComponent,
		CoinReplaceDialogComponent,
		CoinAddDialogComponent,

		CertificatePageComponent,
		CertificateDialogAddComponent,
		CertificateDialogChangeComponent,
		CertificateDialogDeleteComponent,
		CertificateNotificationErrorComponent,
		CertificateHistoryTableComponent,

		StickPageComponent,
		StickDialogAddComponent,
		StickDialogChangeComponent,
		StickDialogDeleteComponent,
		StickNotificationErrorComponent,
		StickReplaceDialogComponent,
		StickAddDialogComponent,

		BookPageComponent,
		BookDialogAddComponent,
		BookDialogChangeComponent,
		BookDialogDeleteComponent,
		BookNotificationErrorComponent,
		BookHistoryTableComponent,
		BookReplaceDialogComponent,
		BookAddDialogComponent,

		ChroniclePageComponent,
		ChronicleDialogAddComponent,
		ChronicleDialogChangeComponent,
		ChronicleDialogDeleteComponent,
		ChronicleNotificationErrorComponent,
		ChronicleHistoryTableComponent,
		ChronicleReplaceDialogComponent,
		ChronicleAddDialogComponent,

		DeliveryNotePageComponent,
		DeliveryNoteDialogAddComponent,
		DeliveryNoteDialogChangeComponent,
		DeliveryNoteDialogDeleteComponent,
		DeliveryNoteNotificationErrorComponent,

		DeliveryNoteOrderDialogAddComponent,
		DeliveryNoteOrderDialogChangeComponent,
		DeliveryNoteOrderDialogDeleteComponent,
		DeliveryNoteOrderNotificationErrorComponent,

		DeliveryNoteReturnDialogAddComponent,
		DeliveryNoteReturnDialogChangeComponent,
		DeliveryNoteReturnDialogDeleteComponent,
		DeliveryNoteReturnNotificationErrorComponent,

		YearBookPageComponent,
		YearBookDialogAddComponent,
		YearBookDialogChangeComponent,
		YearBookDialogDeleteComponent,
		YearBookNotificationErrorComponent,
		YearBookHistoryTableComponent,
		YearBookReplaceDialogComponent,
		YearBookAddDialogComponent,

		BirthdayBookPageComponent,
		BirthdayBookDialogAddComponent,
		BirthdayBookDialogChangeComponent,
		BirthdayBookDialogDeleteComponent,
		BirthdayBookNotificationErrorComponent,
		BirthdayBookHistoryTableComponent,
		BirthdayBookReplaceDialogComponent,
		BirthdayBookAddDialogComponent,

		BirthdayChroniclePageComponent,
		BirthdayChronicleDialogAddComponent,
		BirthdayChronicleDialogChangeComponent,
		BirthdayChronicleDialogDeleteComponent,
		BirthdayChronicleNotificationErrorComponent,
		BirthdayChronicleHistoryTableComponent,
		BirthdayChronicleReplaceDialogComponent,
		BirthdayChronicleAddDialogComponent,

		FolderPageComponent,
		FolderDialogAddComponent,
		FolderDialogChangeComponent,
		FolderDialogDeleteComponent,
		FolderNotificationErrorComponent,
		FolderHistoryTableComponent,
		FolderReplaceDialogComponent,
		FolderAddDialogComponent,

		ArticleDialogAddComponent,
		ArticleDialogChangeComponent,
		ArticleNotificationErrorComponent,
		ArticleHistoryTableComponent,
		ArticlePickDialogComponent,
		ArticleReproduceDialogComponent,
		ArticleHandoverDialogComponent,
		ArticleOrderDialogComponent,
		ArticlePostcheckDialogComponent,
		ArticleReturnDialogComponent,
		ArticlePageComponent,

		ShippingPrepareDialogComponent,
		ShippingCommissionDialogComponent,

		PaymentPayDialogComponent,
		OrderSearchDialogComponent,
		OrderDetailDialogComponent,

		MailTemplateDialogAddComponent,
		MailTemplateDialogChangeComponent,
		MailTemplateDialogDeleteComponent,
		MailTemplateNotificationErrorComponent,

		PermissionDialogAddComponent,
		PermissionDialogChangeComponent,
		PermissionDialogDeleteComponent,
		PermissionNotificationErrorComponent,
		PermissionAutocompleteSingleComponent,
		PermissionAutocompleteMultipleComponent,

		ReceiptPageComponent,
		ReceiptDialogAddComponent,
		ReceiptDialogChangeComponent,
		ReceiptDialogDeleteComponent,
		ReceiptNotificationErrorComponent,

		InvoiceDialogAddComponent,
		InvoiceDialogChangeComponent,
		InvoiceDialogDeleteComponent,
		InvoiceNotificationErrorComponent,
		InvoiceHistoryTableComponent,

		CancellationDialogAddComponent,
		CancellationDialogChangeComponent,
		CancellationDialogDeleteComponent,
		CancellationNotificationErrorComponent,
		CancellationHistoryTableComponent,

		CorrectionDialogAddComponent,
		CorrectionDialogChangeComponent,
		CorrectionDialogDeleteComponent,
		CorrectionNotificationErrorComponent,
		CorrectionHistoryTableComponent,

		OrderPageComponent,
		OrderPageChangeComponent,
		OrderPageAddComponent,
		OrderDialogDeleteComponent,
		OrderNotificationErrorComponent,
		OrderHistoryTableComponent,
		OrdersPositionDialogComponent,
		OrdersReturnPositionDialogComponent,
		OrderAutocompleteSingleComponent,
		ReceiptReceiverDialogComponent,

		ShippingPageComponent,
		ShippingDialogChangeComponent,
		ShippingNotificationErrorComponent,
		ChangeShippingKindDialogComponent,

		UploadedFilePageComponent,

		PaymentPageComponent,
		PaypalPageComponent,
		PaymentDialogChangeComponent,
		ChangePaymentKindDialogComponent,

		RegionPageComponent,
		RegionDialogAddComponent,
		RegionDialogChangeComponent,
		RegionDialogDeleteComponent,
		RegionNotificationErrorComponent,
		RegionAutocompleteSingleComponent,
		RegionAutocompleteMultipleComponent,

		ImagePageComponent,
		ImageDialogAddComponent,
		ImageDialogChangeComponent,
		ImageDialogDeleteComponent,
		ImageNotificationErrorComponent,
		ImageAutocompleteSingleComponent,
		ImageAutocompleteMultipleComponent,

		DocumentPageComponent,
		DocumentDialogAddComponent,
		DocumentDialogChangeComponent,
		DocumentDialogDeleteComponent,
		DocumentNotificationErrorComponent,

		AttachmentPageComponent,
		AttachmentDialogAddComponent,
		AttachmentDialogChangeComponent,
		AttachmentDialogDeleteComponent,
		AttachmentNotificationErrorComponent,
		AttachmentAutocompleteMultipleComponent,

		StockDialogAddComponent,
		StockDialogChangeComponent,
		StockDialogDeleteComponent,
		StockNotificationErrorComponent,
		StockHistoryTableComponent,

		MediaStockPageComponent,
		MediaStockDialogAddComponent,
		MediaStockDialogChangeComponent,
		MediaStockDialogDeleteComponent,
		MediaStockNotificationErrorComponent,
		MediaStockHistoryTableComponent,
		MediaStockAutocompleteSingleComponent,

		AccessoryStockPageComponent,
		AccessoryStockDialogAddComponent,
		AccessoryStockDialogChangeComponent,
		AccessoryStockDialogDeleteComponent,
		AccessoryStockNotificationErrorComponent,
		AccessoryStockHistoryTableComponent,
		AccessoryStockAutocompleteSingleComponent,

		AccountingSummaryPageComponent,
		AccountingSummaryDialogAddComponent,
		AccountingSummaryDialogChangeComponent,
		AccountingSummaryDialogDeleteComponent,
		AccountingSummaryNotificationErrorComponent,
		AccountingSummaryAdvancementNotificationErrorComponent,
		AccountingSummaryCustomerNotificationErrorComponent,
		AccountingSummaryHistoryTableComponent,

		DirectDebitTransferPageComponent,
		DirectDebitTransferDialogAddComponent,
		DirectDebitTransferDialogChangeComponent,
		DirectDebitTransferDialogDeleteComponent,
		DirectDebitTransferNotificationErrorComponent,
		DirectDebitTransferHistoryTableComponent,

		MediaBrandPageComponent,
		MediaBrandDialogAddComponent,
		MediaBrandDialogChangeComponent,
		MediaBrandDialogDeleteComponent,
		MediaBrandNotificationErrorComponent,
		MediaBrandAutocompleteSingleComponent,
		MediaBrandAutocompleteMultipleComponent,

		MediaProductPageComponent,
		MediaProductDialogAddComponent,
		MediaProductDialogChangeComponent,
		MediaProductDialogDeleteComponent,
		MediaProductNotificationErrorComponent,
		MediaProductAutocompleteSingleComponent,

		AccessoryProductPageComponent,
		AccessoryProductDialogAddComponent,
		AccessoryProductDialogChangeComponent,
		AccessoryProductDialogDeleteComponent,
		AccessoryProductNotificationErrorComponent,
		AccessoryProductAutocompleteSingleComponent,

		ProductAutocompleteSingleComponent,

		PublicationCyclePageComponent,
		PublicationCycleDialogAddComponent,
		PublicationCycleDialogChangeComponent,
		PublicationCycleDialogDeleteComponent,
		PublicationCycleNotificationErrorComponent,
		PublicationCycleAutocompleteSingleComponent,

		TextTemplatePageComponent,
		TextTemplateDialogAddComponent,
		TextTemplateDialogChangeComponent,
		TextTemplateDialogDeleteComponent,
		TextTemplateNotificationErrorComponent,
		TextTemplateAutocompleteSingleComponent,
		TextTemplateDropdownSingleComponent,

		PublisherDialogAddComponent,
		PublisherDialogChangeComponent,
		PublisherDialogDeleteComponent,
		PublisherNotificationErrorComponent,

		StoragePlacePageComponent,
		StoragePlaceDialogAddComponent,
		StoragePlaceDialogChangeComponent,
		StoragePlaceDialogDeleteComponent,
		StoragePlaceNotificationErrorComponent,
		StoragePlaceAutocompleteSingleComponent,

		ProcessNodeDialogAddComponent,
		ProcessNodeDialogChangeComponent,
		ProcessNodeDialogDeleteComponent,
		ProcessNodeNotificationErrorComponent,
		ProcessNodeAutocompleteSingleComponent,
		ProcessNodeAutocompleteMultipleComponent,

		ProcessRouteDialogAddComponent,
		ProcessRouteDialogChangeComponent,
		ProcessRouteDialogDeleteComponent,
		ProcessRouteNotificationErrorComponent,
		ProcessRouteAutocompleteSingleComponent,

		ProcessDialogAddComponent,
		ProcessDialogChangeComponent,
		ProcessDialogDeleteComponent,
		ProcessNotificationErrorComponent,

		ConfigurationPageComponent,
		ConfigurationDialogChangeComponent,
		ConfigurationNotificationErrorComponent,
		ShippingTypesTableComponent,
		ShippingTypeDialogComponent,
		PaymentTypesTableComponent,
		PaymentTypeDialogComponent,
		PickupStationDialogComponent,
		PickupStationsTableComponent,

		ShippingHistoryTableComponent,
		PaymentHistoryTableComponent,

		BarzahlenDialogAddComponent,
		BarzahlenDialogChangeComponent,
		BarzahlenDialogDeleteComponent,
		BarzahlenNotificationErrorComponent,

		CashDialogAddComponent,
		CashDialogChangeComponent,
		CashDialogDeleteComponent,
		CashNotificationErrorComponent,
		CashHistoryTableComponent,

		CashOnDeliveryDialogAddComponent,
		CashOnDeliveryDialogChangeComponent,
		CashOnDeliveryDialogDeleteComponent,
		CashOnDeliveryNotificationErrorComponent,
		CashOnDeliveryHistoryTableComponent,

		DirectDebitDialogAddComponent,
		DirectDebitDialogChangeComponent,
		DirectDebitDialogDeleteComponent,
		DirectDebitNotificationErrorComponent,
		DirectDebitHistoryTableComponent,

		PrepaymentDialogAddComponent,
		PrepaymentDialogChangeComponent,
		PrepaymentDialogDeleteComponent,
		PrepaymentNotificationErrorComponent,
		PrepaymentHistoryTableComponent,

		PurchaseOnAccountDialogAddComponent,
		PurchaseOnAccountDialogChangeComponent,
		PurchaseOnAccountDialogDeleteComponent,
		PurchaseOnAccountNotificationErrorComponent,
		PurchaseOnAccountHistoryTableComponent,

		PaypalDialogAddComponent,
		PaypalDialogChangeComponent,
		PaypalDialogDeleteComponent,
		PaypalNotificationErrorComponent,
		PaypalHistoryTableComponent,

		DhlDialogAddComponent,
		DhlDialogChangeComponent,
		DhlDialogDeleteComponent,
		DhlNotificationErrorComponent,
		DhlHistoryTableComponent,

		ReturnDialogAddComponent,
		ReturnDialogChangeComponent,
		ReturnDialogDeleteComponent,
		ReturnNotificationErrorComponent,
		ReturnHistoryTableComponent,

		DhlPremiumDialogAddComponent,
		DhlPremiumDialogChangeComponent,
		DhlPremiumDialogDeleteComponent,
		DhlPremiumNotificationErrorComponent,
		DhlPremiumHistoryTableComponent,

		DhlExpressDialogAddComponent,
		DhlExpressDialogChangeComponent,
		DhlExpressDialogDeleteComponent,
		DhlExpressNotificationErrorComponent,
		DhlExpressHistoryTableComponent,

		CorrectionDialogAddComponent,
		CorrectionDialogChangeComponent,
		CorrectionDialogDeleteComponent,
		CorrectionNotificationErrorComponent,

		CancellationDialogAddComponent,
		CancellationDialogChangeComponent,
		CancellationDialogDeleteComponent,
		CancellationNotificationErrorComponent,

		InvoiceDialogAddComponent,
		InvoiceDialogChangeComponent,
		InvoiceDialogDeleteComponent,
		InvoiceNotificationErrorComponent,

		PickupDialogAddComponent,
		PickupDialogChangeComponent,
		PickupDialogDeleteComponent,
		PickupNotificationErrorComponent,
		PickupHistoryTableComponent,

		PostponeOrderDialogComponent,
		ConfirmationDialogComponent,
		ErrorDialogComponent,
		CommentDialogComponent,
		PositionEditDialogComponent,
		PositionAddDialogComponent,
		BundlePositionAddDialogComponent,
		DatabaseResetDialogComponent,

		UpdatedStoragePlacesDialogComponent,

		UploadedFileNotificationErrorComponent,
		LoginpageComponent,

		MomentModule,
		NgLetModule,
		NgrxFormsModule,
		MatDialogModule,
		ScrollingModule,
		RouterModule,
		FormsModule,
		MatProgressSpinnerModule,
		MatButtonModule,
		MatFormFieldModule,
		MatSelectModule,
		MatListModule,
		MatSidenavModule,
		MatMenuModule,
		MatIconModule,
		MatBadgeModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		MatCardModule,
		MatExpansionModule,
		MatSnackBarModule,
		MatToolbarModule,
		MatTooltipModule,
		MatProgressBarModule,
		CKEditorModule,
		FlexLayoutModule,
		CdkTableModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatTabsModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		DragDropModule,
		MatInputModule,
		NgrxFormsModule,
		MaterialFileInputModule,
		MatAutocompleteModule,
		MatDividerModule,
		MatChipsModule,
		MatInputModule,
	],
	providers: [
		{
			provide: APP_BASE_HREF,
			useValue: '/',
		},

		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},

		{
			provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
			useValue: {
				showDelay: 500,
				hideDelay: 0,
			},
		},

		{
			provide: MatPaginatorIntl,
			useValue: getGermanPaginatorIntl(),
		},

		{
			provide: MAT_DATE_LOCALE,
			useValue: 'de-DE',
		},
		{
			provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: { useUtc: true },
		},
		{
			provide: LOCALE_ID,
			useValue: 'de-DE',
		},

		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},

		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'DD.MM.YYYY',
				},
				display: {
					dateInput: 'DD.MM.YYYY',
					monthYearLabel: 'MMM YYYY',
					dateA11yLabel: 'LL',
					monthYearA11yLabel: 'MMMM YYYY',
				},
			},
		},
		{
			provide: DEFAULT_CURRENCY_CODE,
			useValue: 'EUR',
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: BlobErrorHttpInterceptor,
			multi: true,
		},
	],
})
export class SharedModule {
	constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
		matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/mdi.svg'));
		moment.locale('de');
		registerLocaleData(de);
	}
}
