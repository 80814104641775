import { IArticleNewspaperEditForm, IArticleNewspaperFilterForm } from '../../models/article.model';
import { AppState, IArticleNewspaperState } from '../app.state';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleNewspaper } from '../../models/article.model';
import { EntityFacade } from '../entity.facade';
import { fromArticleNewspaperActions } from './article.newspaper.actions';
import { initialEditFormValue } from './article.newspaper.reducer';
import { ArticleNewspaperSelector } from './article.newspaper.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleNewspaperFacade extends EntityFacade<IArticleNewspaper, IArticleNewspaperState, IArticleNewspaperEditForm, IArticleNewspaperFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleNewspaperActions;

	constructor(store: Store<AppState>, private newspaperSelector: ArticleNewspaperSelector) {
		super(store, newspaperSelector);
	}

	public isSearching$ = this.store.select(this.newspaperSelector.isSearching);
	public isExtending$ = this.store.select(this.newspaperSelector.isExtending);
	public itemsSearched$ = this.store.select(this.newspaperSelector.itemsSearched);
	public paging$ = this.createBehaviorSubject(this.store.select(this.newspaperSelector.paging), null);
	public availableStates$ = this.store.select(this.newspaperSelector.availableStates);

	public search(date: Date): void {
		this.store.dispatch(this.actions.search({ date }));
	}

	public extend(date: Date): void {
		this.store.dispatch(fromArticleNewspaperActions.pagingChanged({ date, paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
	}

	public updateStoragePlace(entity: IArticleNewspaper, storagePlaceId: string): void {
		this.store.dispatch(this.actions.updateStoragePlace({ entity, storagePlaceId: storagePlaceId }));
	}
}
