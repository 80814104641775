import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountingSummaryKind, IAccountingSummary, IAccountingSummaryPosition } from '../../models/accounting-summary.model';
import { IProcessRoute } from '../../models/process-route.model';
import { IAccountingSummaryAdvancement, IAccountingSummaryCustomer } from './../../models/accounting-summary.model';
import { AccountingSummaryAdvancementApiService } from './accounting-summary.advancement.service';
import { AccountingSummaryCustomerApiService } from './accounting-summary.customer.service';
import { IResponse } from './api.service';
import { EntityApiService, IEntitySingleResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryApiService extends EntityApiService<IAccountingSummary<IAccountingSummaryPosition>> {
	protected entityName = 'accounting-summaries';

	constructor(http: HttpClient, private accountingSummaryAdvancementService: AccountingSummaryAdvancementApiService, private accountingSummaryCustomerService: AccountingSummaryCustomerApiService) {
		super(http);
	}

	public changeProcess(
		entity: IAccountingSummary<IAccountingSummaryPosition>,
		processRoute: IProcessRoute,
		comment: string,
		parameters: Record<string, any>,
		authToken: string
	): Observable<IEntitySingleResponse<IAccountingSummary<IAccountingSummaryPosition>>> {
		switch (entity.accountingSummaryKind) {
			case AccountingSummaryKind.ADVANCEMENTS:
				return this.accountingSummaryAdvancementService.changeProcess(entity, processRoute, comment, parameters, authToken);
			case AccountingSummaryKind.CUSTOMERS:
				return this.accountingSummaryCustomerService.changeProcess(entity, processRoute, comment, parameters, authToken);
		}
	}

	public create(entity: IAccountingSummary<IAccountingSummaryPosition>, authToken: string): Observable<IEntitySingleResponse<IAccountingSummary<IAccountingSummaryPosition>>> {
		switch (entity.accountingSummaryKind) {
			case AccountingSummaryKind.ADVANCEMENTS:
				return this.accountingSummaryAdvancementService.create(entity as IAccountingSummaryAdvancement, authToken);
			case AccountingSummaryKind.CUSTOMERS:
				return this.accountingSummaryCustomerService.create(entity as IAccountingSummaryCustomer, authToken);
		}
	}

	public remove(entity: IAccountingSummary<IAccountingSummaryPosition>, authToken: string): Observable<IResponse> {
		switch (entity.accountingSummaryKind) {
			case AccountingSummaryKind.ADVANCEMENTS:
				return this.accountingSummaryAdvancementService.remove(entity as IAccountingSummaryAdvancement, authToken);
			case AccountingSummaryKind.CUSTOMERS:
				return this.accountingSummaryCustomerService.remove(entity as IAccountingSummaryCustomer, authToken);
		}
	}

	public update(entity: IAccountingSummary<IAccountingSummaryPosition>, authToken: string): Observable<IEntitySingleResponse<IAccountingSummary<IAccountingSummaryPosition>>> {
		switch (entity.accountingSummaryKind) {
			case AccountingSummaryKind.ADVANCEMENTS:
				return this.accountingSummaryAdvancementService.update(entity as IAccountingSummaryAdvancement, authToken);
			case AccountingSummaryKind.CUSTOMERS:
				return this.accountingSummaryCustomerService.update(entity as IAccountingSummaryCustomer, authToken);
		}
	}

	public downloadCsv(authToken: string, entity: IAccountingSummary<IAccountingSummaryPosition>): Observable<string> {
		switch (entity.accountingSummaryKind) {
			case AccountingSummaryKind.ADVANCEMENTS:
				return this.accountingSummaryAdvancementService.downloadCsv(authToken, entity as IAccountingSummaryAdvancement);
			case AccountingSummaryKind.CUSTOMERS:
				return this.accountingSummaryCustomerService.downloadCsv(authToken, entity as IAccountingSummaryCustomer);
		}
	}
}
