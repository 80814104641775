import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IArticleNewspaper, IArticleNewspaperEditForm, IArticleNewspaperFilterForm } from '../../models/article.model';
import { IProcessNode } from '../../models/process-node.model';
import { AppState, IArticleNewspaperState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class ArticleNewspaperSelector extends EntitySelector<IArticleNewspaper, IArticleNewspaperState, IArticleNewspaperEditForm, IArticleNewspaperFilterForm> {
	protected stateSelector = (state: AppState): IArticleNewspaperState => state.articleNewspaper;

	public isSearching = createSelector(this.selectState, state => state.isSearching);
	public isExtending = createSelector(this.selectState, state => state.isExtending);
	public itemsSearched = createSelector(this.selectState, state => state.itemsSearched);
	public paging = createSelector(this.selectState, state => state.paging);
	public dayRange = createSelector(this.selectState, state => state.dayRange);

	public availableStates = createSelector(
		this.list,
		(state: AppState) => Object.values(state.processNode.items),
		(articles: IArticleNewspaper[], nodes: IProcessNode[]) => {
			const stateIds = articles.map(article => article.processInstance?.state).filter((stateId): stateId is string => !!stateId);
			const uniqueStateIds = [...new Set(stateIds)];
			return nodes.filter((node: IProcessNode) => uniqueStateIds.includes(node._id));
		}
	);
}
