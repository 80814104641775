import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, Observable, map, withLatestFrom } from 'rxjs';
import { ArticleKind, IArticleMagazineEditForm, IArticleMagazineFilterForm } from './../../models/article.model';

import { SelectionModel } from '@angular/cdk/collections';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleMagazineState } from 'src/state/app.state';
import { IArticleMagazine } from '../../models/article.model';
import { ArticleMagazineDialogService } from '../../services/dialog/article.magazine.service';
import { ArticleMagazineFacade } from '../../state/article.magazine/article.magazine.facade';
import { ArticleFacade } from '../../state/article/article.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { ProcessNodeFacade } from './../../state/process-node/process-node.facade';
import { StoragePlaceFacade } from './../../state/storage-place/storage-place.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-magazinepage',
	templateUrl: './entitypage-magazine.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class MagazinePageComponent extends EntityPageComponent<IArticleMagazine, IArticleMagazineState, IArticleMagazineEditForm, IArticleMagazineFilterForm> {
	public pageTitle$ = new BehaviorSubject('Zeitschriften');
	public columns = ['select', 'updatedAt', 'order', 'product', 'storagePlace', 'quality', 'publicationDate', 'assignee', 'state', 'filter'];
	public selection = new SelectionModel<IArticleMagazine>(true, []);
	public masterSelection$ = new BehaviorSubject<boolean>(false);
	public selectAllFiltered: boolean = false;
	public availableStates$ = this.entityFacade.availableStates$;

	constructor(
		titleService: Title,
		route: ActivatedRoute,
		private magazineDialogService: ArticleMagazineDialogService,
		public entityFacade: ArticleMagazineFacade,
		public orderFacade: OrderFacade,
		public mediaProductFacade: MediaProductFacade,
		public mediaStockFacade: MediaStockFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public processFacade: ProcessFacade,
		private articleFacade: ArticleFacade
	) {
		super(titleService, magazineDialogService, route, entityFacade);
		this.entityFacade.changeSorting({ updatedAt: -1 });

		this.masterSelection$
			.pipe(
				withLatestFrom(this.entityFacade.listFiltered$),
				map(([selectAll, list]) => {
					if (selectAll) {
						this.selection.select(...list);
					} else {
						this.selection.clear();
					}
				})
			)
			.subscribe();
	}

	public get isAllSelected$(): Observable<boolean> {
		return this.entityFacade.listFiltered$.pipe(map(list => list.length == this.selection.selected.length));
	}

	public updateStoragePlace(storagePlaceId: string): void {
		if (!storagePlaceId) {
			return;
		}

		if (this.selectAllFiltered) {
			this.articleFacade.updateStoragePlaces(ArticleKind.Magazine, storagePlaceId);
		} else {
			for (let article of this.selection.selected) {
				this.articleFacade.updateStoragePlace(ArticleKind.Magazine, article, storagePlaceId);
			}
		}

		this.selection.clear();
	}

	public removeSelected(): void {
		this.entityFacade.totalCount$
			.pipe(
				map(count => {
					const totalDeleteCount = this.selectAllFiltered ? count : this.selection.selected.length;

					this.magazineDialogService.openConfirmationDialog({
						title: 'Verwaltung Zeitschriften',
						text: `${totalDeleteCount} Exemplare werden gelöscht.`,
						confirmButtonText: 'Abbrechen',
						rejectButtonText: 'Löschen',
						onConfirm: () => {},
						onReject: () => {
							if (this.selectAllFiltered) {
								this.articleFacade.deleteByFilter(ArticleKind.Magazine);
							} else {
								for (let article of this.selection.selected) {
									this.entityFacade.remove(article);
									this.entityFacade.removed();
								}
							}
							this.selection.clear();
						},
					});
				})
			)
			.subscribe();
	}

	public isSafeDeleteSelected(): boolean {
		return this.selection.selected.every(article => !article.order);
	}
}
