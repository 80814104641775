import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IPostponeOrderDialogData } from '../../services/dialog/dialog.service';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-postpone-order-dialog',
	templateUrl: './dialog-postpone-order.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [progressFade],
})
export class PostponeOrderDialogComponent extends DialogComponent<PostponeOrderDialogComponent, IPostponeOrderDialogData> {
	public formGroup: FormGroup;

	constructor(matDialogRef: MatDialogRef<PostponeOrderDialogComponent, IPostponeOrderDialogData>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IPostponeOrderDialogData) {
		super(matDialogRef, data);

		this.formGroup = formBuilder.group({
			resubmissionDate: [null, Validators.required],
		});
	}
}
